<template>
    <div>
        <h4 class="mb-4">Lista de grupos</h4>
        <div class="table-responsive" v-if="Object.keys(sortedCategories).length">
            <table id="datatable" class="table">
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Puestos</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr  v-for="(group, i) in groups" :key="i" >
                        <td>{{group.title}}</td>
                        <td class="d-flex flex-wrap w-74">
                              <div class="badge badge-light mr-2 mb-2" v-for="(category, i) in group.categories" :key="i">
                                <span v-if="sortedCategories[category]">{{ sortedCategories[category].label }}</span>
                              </div>
                            </td>
                        <td>
                            <div class="btn btn btn-primary ml-2" @click.prevent="$emit('update',{...group,...{fireId:i}})">Modificar</div>
                            <div class="btn btn btn-danger ml-2" @click.prevent="$emit('delete',i)">Eliminar</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { tableConfig } from "@/env";
import $ from 'jquery'
export default {
    name:'ListGroups',
    props: {groups:Object,categories:Array},
    data() {
        return {
            sortedCategories:{}
        }
    },
    mounted(){
        console.log(this.categories)
        this.categories.forEach((c)=>{
            
            this.sortedCategories[c.value] = c
        });

        this.$nextTick(()=>{
            $('#datatable').dataTable().fnDestroy();
            $('#datatable').DataTable(tableConfig);
        })
    }
}
</script>

<style>

</style>