<template>
    <h4 class="mb-4">Configuración de categorias</h4>
  <div class="form-row mb-4" v-if="!trigger.update">
      <div class="col">
          <label class="form-label">Nueva categoría</label>
          <div class="d-flex">
              <input type="text" class="form-control" v-model="category.label">
              <button class="btn btn-success w-25 ml-3" @click.prevent="handleNewCategory()">Agregar categoría</button>
          </div>
          <div class="text-danger" v-if="error.category.label">&otimes; {{error.category.label}}</div>
      </div>
  </div>
  <div class="form-row mb-3" v-if="trigger.update">
      <div class="col-8">
          <label class="form-label">Actualizar categoría</label>
          <input type="text" class="form-control" v-model="category.label">
          <div class="text-danger" v-if="error.category.label">&otimes; {{error.category.label}}</div>
      </div>
      <div class="col d-flex align-items-end">
          <button class="btn btn-light mr-2" @click.prevent="handleCancelUpdate()">Cancelar</button>
          <button class="btn btn-success" @click.prevent="handleCategoryUpdate()">Modificar categoría</button>
      </div>
  </div>
  <div class="row">
      <div class="col-12">
          <h5 class="mb-3">Categorias existentes</h5>
        <div class="card shadow-none">
            <ul class="list-group list-group-flush">
                <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(category,c) in categories" :key="c">
                    <div>{{capitalizeR(category.label)}}</div>
                    <div>
                        <button class="btn btn-primary mr-2" @click="handleEditCategory(c)">Editar</button>
                        <button class="btn btn-danger" @click="handleCategoryDelete(c)">Eliminar</button>
                    </div>
                </li>
                <li class="list-group-item" v-if="categories == null">
                    <div class="alert alert-light mb-0">No hay categorias, agregá una nueva</div>
                </li>
            </ul>
        </div>
      </div>
  </div>
</template>

<script>
import { getDataFrom, isEmpty, capitalize } from '../../utils'
import firebase from "firebase/app";
import "firebase/database";

export default {
    name:'CategoriesManager',
    props: {},
    data() {
        return {
            category:{label:null},
            categories:null,
            selected:null,
            error:{category:{label:false}},
            trigger:{update:false},
        }
    },
    mounted(){
        this.getCategories();
    },
    methods:{
        getCategories(){
            getDataFrom('resource_categories').then((data)=>{
                this.categories=data;
            });
        },
        handleCategoryDelete(id){
            let promises = [];
    
            getDataFrom(`https://fs-capacitacion-default-rtdb.firebaseio.com/resources.json?orderBy="category"&equalTo="${this.categories[id].label}"`,false).then((data)=>{
                Object.keys(data).forEach((id)=>{
                    promises.push(firebase.database().ref('resources/'+id+'/category').remove());
                })
            });
    
            Promise.all(promises).then(()=>{
                firebase.database().ref('resource_categories/'+id).remove().then(()=>{
                    console.log('%c✓ the category was deleted','color:#0f0');
                    this.getCategories();
                    this.$emit('update-categories');
                })
            });
        },
        handleCategoryUpdate(){
            this.error.category.label = false;

            if(isEmpty(this.category.label)) {
                this.error.category.label = 'Debe asignar un nombre';
                return null;
            }

            this.category.label = this.category.label.toLowerCase();

            let exist;
            if(this.categories!=null){
                exist = Object.keys(this.categories).find((id)=>this.categories[id].label == this.category.label);
            }

            
            if(exist == undefined){
                let productsUpdated = 0;
                let promises = [];
    
                getDataFrom(`https://fs-capacitacion-default-rtdb.firebaseio.com/resources.json?orderBy="category"&equalTo="${this.categories[this.selected].label}"`,false).then((data)=>{
                    Object.keys(data).forEach((id)=>{
                        console.log('resources/'+id);
                        console.log({category:this.category.label});
                        // data[id]['category'] = this.category.label;
                        promises.push(firebase.database().ref('resources/'+id).update({category:this.category.label}));
                        productsUpdated++;
                    })  
                });
    
                Promise.all(promises).then(()=>{
                    console.log(`%c✓ ${productsUpdated} resources was updated`,'color:#0f0');
                
                    firebase.database().ref('resource_categories/'+this.selected).update(this.category).then(()=>{
                        // this.category.label = null;
                        this.error.category.label = false;
                        this.selected = null;
                        this.trigger.update = false;
                        console.log('%c✓ the category was updated','color:#0f0');
                        this.getCategories();
                        this.$emit('update-categories');
                    });
                })
            }else {
                this.error.category.label = 'La categoría existe';
            }
        },
        handleEditCategory(id){
            this.selected = id;
            this.trigger.update = true;
            this.category.label = this.categories[id].label;
        },
        handleNewCategory(){
            this.error.category.label = false;

            if(isEmpty(this.category.label)) {
                this.error.category.label = 'Debe asignar un nombre';
                return null;
            }

            this.category.label = this.category.label.toLowerCase();

            let exist;
            if(this.categories!=null){
                exist = Object.keys(this.categories).find((id)=>this.categories[id].label == this.category.label);
            }

            if(exist == undefined){
                firebase.database().ref('resource_categories').push(this.category).then(()=>{
                    this.category.label = null;
                    this.error.category.label = false;
                    console.log('%c✓ a new category was added','color:#0f0');
                    this.getCategories();
                    this.$emit('update-categories');
                });

            }else {
                this.error.category.label = 'La categoría existe';
            }
        },
        handleCancelUpdate(){
            this.selected = null;
            this.trigger.update = false;
            this.category.label = null;
        },
        capitalizeR(s){return capitalize(s)}
    },
}
</script>

<style>

</style>