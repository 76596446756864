<template>
  <div class="page-content">
		<!-- Breadcrumb -->
		<nav class="page-breadcrumb" id="breadcrumb">
			<ol class="breadcrumb">
			<li class="breadcrumb-item">{{breadcrumbA}}</li>
			</ol>
		</nav>
		<!-- Page title -->
		<div class="row">
			<div class="col-md-12 grid-margin stretch-card">
				<div class="card dash-card">
					<div class="card-body">
						<h4 class="dash-title mb-2">{{bienvenida}}</h4>
						<p class="dash-description">{{descripcion}}</p>
					</div>
				</div>
			</div>
                    
		</div>

		<!-- <div class="row">
			<div class="col">
				<div class="card">
					<div class="card-body">
						<h4>Test</h4>
						<p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Ad necessitatibus voluptas et sint quod ea! Totam cupiditate exercitationem unde laborum repellendus voluptates architecto minus nobis corporis hic dolores, laboriosam suscipit, non magnam! Quia porro officia minima, exercitationem incidunt eum, fugit, placeat soluta sunt aperiam cum voluptatem ipsum. Repellendus, magni. Repellat ipsum enim magnam ad doloremque dignissimos. Consequatur laudantium fugit nobis. Eveniet officia eius, saepe corporis, consectetur voluptatem dolore, voluptas quo praesentium molestias ipsum quisquam consequatur nihil odio nostrum accusamus cumque mollitia maiores dolorum! Ipsa commodi perferendis magnam accusantium quod quo, minima quos illum temporibus sint veniam, neque, eos voluptate dolores?</p>
						<Popper :class="light" content="This is the Popper content">
							<button>Trigger element</button>
						</Popper>
						<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi dignissimos magnam sunt assumenda adipisci tempore a porro eveniet eos odio iste vel vitae, corporis officia ipsa laboriosam consectetur, perspiciatis architecto amet aliquam dicta similique culpa autem. Ut, aut, quisquam consectetur recusandae perferendis velit accusamus ullam quos repudiandae, iure enim eius cumque repellat dolor excepturi est nihil? Doloribus assumenda magnam, eius tempore voluptatum nesciunt totam sunt dolores soluta sapiente eos! Facere impedit architecto nostrum ut quis reiciendis fugit autem atque? Itaque quia magnam necessitatibus ipsa maiores voluptas velit, molestias dolorum suscipit optio odit iusto quasi nisi. Aperiam iusto possimus ipsum voluptas?</p>
					</div>
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>
import { 
	capitalize,
	// getDataFrom 
} from "@/utils";
import APP_DATA from "@/env";
// import firebase from "firebase/app";
// import "firebase/database";
// import Popper from "vue3-popper";
export default {
    name: 'Dashboard',
	// components: {
    //   Popper,
    // },
    data() {
        return {
			breadcrumbA: 'Dashboard',
            descripcion: APP_DATA.description,
			bienvenida: '',
		}
    },
	mounted() {
		const userData = JSON.parse(sessionStorage.getItem("userData"));

		var hora = new Date().getHours();

		if(hora >= 0 && hora < 12){
			this.bienvenida = "¡Buenos días";
		}else if(hora >= 12 && hora < 20){
			this.bienvenida = "¡Buenas tardes";
		}else { this.bienvenida = "¡Buenas noches"; }

		this.bienvenida += ` ${capitalize(userData.leg_nombre.split(' ')[0])}!`;

		// getDataFrom('resources',true).then((data)=>{
			
		// 	let normalizedR = {}

		// 	Object.keys(data).forEach((id)=>{
		// 		if(data[id].file_url != undefined){
		// 			normalizedR[id] = data[id];
		// 			normalizedR[id]['files'] = [data[id].file_url];	
		// 			delete normalizedR[id].file_url;
		// 			firebase.database().ref('resources/'+id).update(normalizedR[id]).then(()=>{
		// 				console.log(`%ccourse ${data[id].title} was updated`);
		// 			})
		// 		}



		// 	});


		// 	console.log(JSON.parse(JSON.stringify(normalizedR)));

			
		// })

	}
}
</script>

<style>
.dash-card {
	background-color: #00DBDE;
	background-image: linear-gradient(205deg, #00DBDE 0%, #FC00FF 100%);
	padding: 20px 0;
	color: #fff;
	border:none
}

.dash-title {
	font-size: 1.2rem;
	margin: 0;
}

.dash-description {
	margin: 0;
}

/* :root {
  --popper-theme-background-color: #dbdbdb;
  --popper-theme-background-color-hover: #dbdbdb;
  --popper-theme-text-color: #1d1d1d;
  --popper-theme-border-width: 0;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 12px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}

.popper:after{
	content: "";
	position: absolute;
	border-left: 12px solid transparent;
	border-right: 12px solid transparent;
	border-bottom: 12px solid #dbdbdb;
	right: 43%;
	top: -12px;
} */

</style>

