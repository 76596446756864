<template>
  <div class="page-content">
    <!-- Navegación superior tipo breadcrumb -->
    <nav class="page-breadcrumb mb-2" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
          <router-link :to="{ name: 'Dashboard' }" class="text-dark">Inicio</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Desaprobados
        </li>
      </ol>
    </nav>
    <!-- List -->
    <div class="row mb-3">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body pt-2">
            <h4 class="my-2">Cursos desaprobados</h4>
            <select v-model="selectedCourse" v-if="trigger.select">
              <option :value="null" disabled>Seleccione un curso para ver los empleados</option>
              <option :value="c" v-for="c in disapprovedCourses" :key="c" >{{courses[c].title}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="trigger.list && selectedCourse != null">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body pt-2">
            <list
              title="Lista de personal desaprobado"
              :data="disapproved[selectedCourse]"
              :columns="[
                {label:'Documento',trackBy:'dni'},
                {label:'Nombre',trackBy:'nombre',format:'capitalize'},
                {label:'Legajo',trackBy:'legajo'},
                {label:'Curso',trackBy:'curso_name'},
              ]"
              :actions="[{color:'primary',label:'Habilitar',emitter:'enable-exam'}]"
              @enable-exam="enableExam($event)"
            ></list>
          </div>
        </div>
      </div>
    </div>
    <!-- 
    <div class="row" v-if="trigger.employeeDetail">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <employee-evaluated
              :employee="selection"
              :courses="courses"
              @ended="refreshTriggers('list')"
            ></employee-evaluated>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
/* eslint-disable*/
const fireAplicativos = firebase.apps[1];

import List from "@/components/List";
import { getDataFrom } from "@/utils";
// import EmployeeEvaluated from '@/components/Reports/EmployeeEvaluated';
export default {
    name:'Evaluated',
    components:{
      List,
      // EmployeeEvaluated
    },
    data() {
      return {
        trigger:{list:false,select:false},
        toList:null,
        disapproved:null,
        disapprovedCourses:[],
        selectedCourse:null,
        courses:null,
        selection:null,
      }
    },
    mounted(){
      this.personal = [];
      getDataFrom('courses',true).then((cursos)=>{
        
        this.courses = cursos;

        getDataFrom('https://aplicativos.firebaseio.com/course_disabled.json',false).then((disapproved)=>{
          this.disapproved = disapproved;
          // Iterate over disapproved to know which courses are disapproved
          Object.keys(this.disapproved).forEach((id)=>{
            console.log(id)
            console.log(cursos[id])
            if (cursos[id]!=undefined) {
            this.disapprovedCourses.push(id);
            }
          });

          this.trigger.list = true;
          this.trigger.select = true;
        })
        
      });
    },
    methods: {
      enableExam(user){
        console.log(user);
        this.trigger.list = false;
        fireAplicativos.database().ref('course_disabled/'+this.selectedCourse+'/'+user.dni).remove().then(()=>{
          delete this.disapproved[this.selectedCourse][user.dni];
          this.trigger.list = true;
        })
      },

      getEmployeeCategory(legajo,distribucion,categorias){
        // Se itera sobre las operaciones/categorias
        let categoria_id = null;
        Object.keys(distribucion).some((d)=>{
          // TODO se puede mejorar obteniendo previamente las categorías del curso.
          // d representa una operacion o una categoria, acá importan las categorias.
          // Se itera sobre los empleados en una categoria
          return Object.keys(distribucion[d]).some((empleadoId)=>{
            // se busca el legajo
            if(distribucion[d][empleadoId].legajo == legajo){
              categoria_id = d;
              return true;
            } else {
              false;
            }
          })
        });
        // console.log('se busca categoria',categoria_id);
        let categoria = null;
        // Se busca la categoria correspondiente
        Object.keys(categorias).some((s)=>{
          if(categorias[s].codigo == categoria_id){
            categoria = categorias[s];
            return true;
          }
        });

        return categoria;
      },
      refreshTriggers(active) {
        Object.keys(this.trigger).forEach((t) => { this.trigger[t] = false });
        if(active!=undefined) this.trigger[active] = true
      },
      handleEvent(action,selection){
        this.selection = selection;
        this.refreshTriggers(action);
      },

      
    }
}
</script>

<style>

</style>  