<template>
    <div>

        <div class="card mb-3">
            <div class="card-body d-flex-center-between">
                <h4>
                    Exámenes en el módulo: {{module.title}}
                </h4>
                <!-- <button class="btn btn-primary" @click.prevent="addExam()">Añadir exámen</button> -->
                <button class="btn btn-light" @click.prevent="$emit('return',course)">&larr; Lista de módulos</button>
            </div>
        </div>

        <div class="alert alert-info custom-alert custom-alert-info" v-if="!Object.keys(exams).length">
            No hay exámenes en esté módulo, agregá uno nuevo
        </div>



        <div class="card mb-3" v-for="(exam,e,n) in exams" :key="e">
            <div class="card-body">
                <div class="d-flex-center-between mb-2">
                    <h4 class="">Examen {{n+1}}</h4>
                    <button class="btn btn-danger" @click.prevent="removeExam(e)">Eliminar examen</button>
                </div>
                <div class="form-row">
                    <div class="col-12 mb-3">
                        <label class="form-label">Título</label>
                        <input type="text" class="form-control" v-model="exam.title">
                        <div class="text-danger mt-1" v-if="error.exam[e].title">&otimes; {{error.exam[e].title}}</div>
                    </div>
                    <div class="col-12 mb-3">
                        <label class="form-label">¿A que puestos está dirigido?</label>
                         <multiselect 
                            v-model="exam.categories"
                            mode="tags"
                            :close-on-select="false"
                            :searchable="true"
                            :create-option="false"
                            :options="categories"
                            class="multiselect-blue"
                            :placeholder="'Agregue sectores, puede buscar y seleccionar'"
                            @select="handleCategorySelect"
                         ></multiselect>
                        <!-- <input type="text" class="form-control" v-model="exam.title"> -->
                        <!-- <div class="text-danger mt-1" v-if="error.title">{{error.title}}</div> -->
                    </div>

                    <div class="col-12 mb-3 mt-2" v-if="Object.keys(exam.questions).length">
                        <h5>Preguntas y respuestas del exámen {{n+1}}</h5>
                    </div>

                    <div class="col-12 mb-3" v-for="(question,q,i) in exam.questions" :key="q">
                        <div class="border border-light p-4 pt-3 rounded mb-3" style="box-shadow: rgba(0, 0, 0, 0.10) 0px 0px 8px">
                            <div class="d-flex-center-between mb-2">
                                <h5 class="mb-1">Pregunta número {{i+1}}</h5>
                                <button class="btn btn-danger" style="min-width: fit-content;" @click.prevent="removeQuestion(exam,q)">Eliminar pregunta</button>
                            </div>

                            <div class="mb-2">
                                <div class="form-label">Enunciado</div>
                                <input type="text" class="form-control w-100 d-block" v-model="question.statement" @keyup.enter="removeQuestion(q)">
                                <div class="text-danger mt-1" v-if="error.exam[e].questions[q].statement">&otimes; {{error.exam[e].questions[q].statement}}</div>
                            </div>
                            
                             <input type="file" accept="image/*" :id="'file-'+q" @change="handleImagePicked($event,question)"/>

                              <div class="form-label">Imagen</div>
                                <div class="d-flex-center-between image-box">
                                    <button class="btn btn-primary" @click.prevent="openFileDialog('file-'+q)">Seleccionar</button>
                                    <img :src="question.image" alt="" v-if="question.image">
                                    <div class="bg-light no-image" v-else @click.prevent="openFileDialog('file-'+q)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-image" viewBox="0 0 16 16">
                                            <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                            <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/>
                                        </svg>
                                    </div>
                                    <button class="btn btn-danger rounded-circle p-2" @click.prevent="removeImage(question)" v-if="question.image">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16"> <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/> </svg>
                                    </button>
                                </div>
                            

                            <!-- <div class="text-danger mt-1" v-if="error.question[q]">&otimes; {{error.question[q]}}</div> -->
                            <h5 class="mb-2 mt-3">Respuestas</h5>
                            <div v-for="(answer,a) in question.answers" :key="a">
                                <div class="d-flex align-items-center justify-content-between mb-2">
                                    <div class="w-75 mr-2 d-flex">
                                        <input type="text" class="form-control d-block" v-model="answer.label" v-if="!answerWithImage(question)">

                                        <div class="answer-image-box" v-if="answerWithImage(question)">
                                            <img :src="answer.image" v-if="answer.image">
                                            <div class="bg-light no-image" v-if="!answer.image" @click.prevent="openFileDialog('file-answer-'+a)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-image" viewBox="0 0 16 16">
                                                    <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                                    <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/>
                                                </svg>
                                            </div>
                                            <button class="btn btn-danger rounded-circle p-2" v-if="answer.image" @click.prevent="removeImage(answer)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16"> <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/> </svg>
                                            </button>
                                        </div>

                                       <!-- <button class="btn btn-light ml-2" @click.prevent="openFileDialog('file-answer-'+a)">Imagen</button>-->
                                        <div class="custom-file-upload">
                                            <input type="file" accept="image/*" :id="'file-answer-'+a" @change="handleImagePicked($event,answer)"/>
                                            <button for="file-input" class="btn btn-light ml-2" style="cursor: pointer;">Imagen</button>
                                        </div>
                                    </div>
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" v-model="answer.correct" :id="answCheck+q+a">
                                        <label class="form-check-label" :for="answCheck+q+a">Correcta</label>
                                    </div>
                                    <button class="btn btn-danger" @click.prevent="removeQuestionAnswer(question,a)">x</button>
                                </div>
                            </div>
                            <div class="text-danger mt-1" v-if="error.exam[e].questions[q].answers">&otimes; {{error.exam[e].questions[q].answers}}</div>
                            <div class="btn btn-primary w-100 mt-2" @click.prevent="addQuestionAnswer(question)">Agregar respuesta</div>
                        </div>
                    </div>


                    <div class="col-12 mb-3 mt-2">
                        <label class="form-label">Nueva pregunta</label>
                        <div class="d-flex-center-between">
                            <input type="text" class="form-control" @keyup.enter="addQuestion(exam,e,$event)">
                            <button class="btn btn-primary ml-3" style="min-width: fit-content;" @click.prevent="addQuestion(exam,e,$event)">Agregar pregunta</button>
                        </div>
                        <div class="text-danger mt-1" v-if="error.exam[e].questionsLength">&otimes; {{error.exam[e].questionsLength}}</div>
                    </div>



                </div>
            </div>
        </div>

        <div class="card mb-3">
            <div class="card-body d-flex align-items-center justify-content-end">
                <button class="btn btn-primary mr-2" @click.prevent="addExam()">Añadir exámen</button>

                <button class="btn btn-success" disabled v-if="uploadingImages>0">
                    <div class="spinner-border" style="width:12px;height:12px" role="status">
                        <span class="sr-only">Loading...</span>
                    </div> Guardando imágenes
                </button>
                <button class="btn btn-success" @click.prevent="submit($event)" v-else>Guardar</button>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import {getDataFrom,putBase64,getUniqueId,setProp,isEmpty} from '@/utils';

import firebase from "firebase/app";
import "firebase/database";

export default {
    name:'ManageExams',
    props:{course:Object,module:Object},
    components:{
        Multiselect,
        // InputFile
    },
    data() {
        return {
            exams:{},
            categories:[],
            groups:null,
            error:{exam:{}},
            uploadingImages:0,
        }
    },
    // computed:{
        
    // },
    mounted(){
        if(this.module.exams!=undefined) {
            this.exams = JSON.parse(JSON.stringify(this.module.exams));
            Object.keys(this.exams).forEach((examId)=>{
                this.error.exam[examId] = {
                    title:'',
                    questions:{},
                    questionsLength:'',
                }

                if(this.exams[examId].questions!=undefined){
                    Object.keys(this.exams[examId].questions).forEach((qId)=>{
                        this.error.exam[examId].questions[qId] = {
                            answers:'',statement:''
                        }
                    })
                }
            })
        }

        
        getDataFrom('https://aplicativos.firebaseio.com/categorias.json', false).then((data)=>{
            
            let categoriesAndGroups = [];
            
            categoriesAndGroups = Object.keys(data).map((d)=>{
                return {
                    value: data[d].codigo,
                    label: data[d].nombre
                }
            });

            getDataFrom('groups').then((data)=>{
                this.groups = data;
                Object.keys(data).forEach((d)=>{
                    categoriesAndGroups.unshift({
                        value: d,
                        label: `${data[d].title} (GRUPO)`
                    });
                });


                Object.keys(this.exams).forEach((eId)=>{
                    if(this.exams[eId].categories == undefined) return;
                    // Para trabajar sobre el array iterado se crea el mismo array pero perdiendo la referencia con JSON.parse
                    let categories = JSON.parse(JSON.stringify(this.exams[eId].categories));
                    // console.log('full_categ',categories);

                    Object.keys(this.groups).forEach((g)=>{

                        if(this.groups[g].categories.length > categories.length) return // No es este grupo (tiene mas puestos que el grupo iterado)

                        let indexes = [];
                        // let candidate = JSON.parse(JSON.stringify(categories));
                        // console.log('cats',this.groups[g].categories);
                        this.groups[g].categories.some((groupCategory,gC)=> {

                            // console.log(categories);
                            categories.some((examCategory,indx)=>{
                                if(examCategory==groupCategory){
                                    // console.log(examCategory+"="+groupCategory);
                                    // console.log('se spliceel indeice:',indx);
                                    categories.splice(indx,1);
                                    // console.log(`cands tras splice de ${examCategory}` , categories);
                                    indexes.push(indx);
                                    return true;
                                }
                            });
                            if(indexes.length<gC+1) return true;
                        });
                   
                        if(indexes.length == this.groups[g].categories.length){
                            // TODO Eliminar options del select de puestos (Se deberían volver a agregar al selecte si se elimina como tag seleccionado)
                            this.exams[eId].categories = categories;
                            this.exams[eId].categories.push(g);
                        }
                    })
                })



                
                this.categories = categoriesAndGroups;
            });
        });
        
    },
    methods:{
        answerWithImage(question){

            console.log(JSON.parse(JSON.stringify(question)));
            return question.answers.some((ans)=>{
                console.log(ans['image'] +"!=" + 'null');
                if (ans['image']!=null) {
                    return true;
                }
            });
        },
        handleCategorySelect(event){ console.log(event); },
        removeImage(entity){ entity.image = null },
        openFileDialog(input) { 
            console.log(input)
            
            //document.getElementById(input).click()

             },
        
        handleImagePicked(e,entity){
            let file = e.target.files[0];
            
            
            let reader = new FileReader()
            reader.onload = (event) => {
                let img = new Image();
                img.src = event.target.result;
    
                img.onload = () => {
                    let canvas = document.createElement('canvas')
                    const MAX_WIDTH = 900
                    const MAX_HEIGHT = 900
                    let width = img.width
                    let height = img.height
    
                    if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width
                        width = MAX_WIDTH
                    }
                    } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height
                        height = MAX_HEIGHT
                    }
                    }
                    canvas.width = width
                    canvas.height = height
                    let ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0, width, height)
                    let data = canvas.toDataURL();
                    
                    entity.image = data;

                    this.$forceUpdate();
                    this.uploadingImages++;

                    putBase64(data).then((url)=>{
                        this.uploadingImages--;
                        if (entity.image) {
                            entity.image = url;
                        }
                    });
                }
            }
            reader.readAsDataURL(file)
        },
        removeQuestion(exam,qId){
            delete exam.questions[qId];
        },
        removeQuestionAnswer(question,a){
            question.answers.splice(a,1);
        },
        addQuestionAnswer(question){

            question.answers.push({
                label:null,
                correct:false,
                image:null,
            });
        },
        addQuestion(exam,examId,event){
            console.log(examId);
            let input;
            if(event.target.tagName!=='INPUT'){
                // Get the input value, first get the parent and then get the input
                event.target.parentElement.childNodes.forEach((e)=>{if(e.tagName=='INPUT')input=e});
            }else {
                input = event.target;
            }

            // Add the question
            let id = getUniqueId();
            setProp(exam.questions,id,{
                statement:input.value.trim(),
                answers:[],
                image:null,
            });

            setProp(this.error.exam[examId].questions,id,{answers:'',statement:''});

            input.value = '';

            this.$forceUpdate();
        },
        removeExam(e){
            delete this.exams[e];
            this.$forceUpdate();
        },
        addExam(){
            let id = getUniqueId();
            setProp(this.exams,id,{
                title:null,
                questions:{},
                categories:[],
            });

            setProp(this.error.exam,id,{
                title:'',
                questions:{},
                questionsLength:'',
            });

            // Vue no detecta como un cambio agregar una propiedad al objeto. Hay que forzar la actualización
            this.$forceUpdate();
        },
        submit(e){
            if(!Object.keys(this.exams).length) return null;

            e.target.innerHTML = `
                <div class="spinner-border" style="width:12px;height:12px" role="status">
                    <span class="sr-only">Loading...</span>
                </div> Creando
            `
            e.target.disabled = true;

            for (let input of document.querySelectorAll('form input,form select,form textarea,form button')) {
                input.disabled = true;
            }


            if(!this.isValid()){
                e.target.innerHTML = 'Guardar';
                e.target.disabled = false;
                for (let input of document.querySelectorAll('form input,form select,form textarea,form button')) {
                    input.disabled = false;
                }
                return null;
            }

            // Valid form, then upload
            
            Object.keys(this.exams).forEach((eId)=>{
                if(this.exams[eId].categories!=undefined){
                    // Para trabajar sobre el array iterado se crea el mismo array pero perdiendo la referencia con JSON.parse
                    let categories = JSON.parse(JSON.stringify(this.exams[eId].categories));
                    let categoriesToAdd = [];

                    categories.forEach((c)=>{
                        if(this.groups[c]!=undefined){
                            
                            let indX = this.exams[eId].categories.findIndex((n)=>n==c);
                            if(indX>=0) this.exams[eId].categories.splice(indX,1);
                            
                            categoriesToAdd.push(...this.groups[c].categories);
                        }
                    });

                    // add the categories founded to the categories exam
                    this.exams[eId].categories.push(...categoriesToAdd);
                }
            })

            // se guardan todas las categorias en el nivel de course
            let all_categories = [];
            // Para eso se recorren todos los examenes del curso
            let exams = {...this.exams}; // Se añaden los examenes actuales
            
            Object.keys(this.course.modules).forEach((mId)=>{
                // Se saltan los examenes actuales ya que no estan actualizados. 
                // Se sumaron ya en exams los que se estan editando actualmente
                if(this.module.fireId == mId) return;
                // El modulo no tiene examenes
                if(this.course.modules[mId].exams == undefined) return;



                Object.keys(this.course.modules[mId].exams).forEach(e => {
                    
                    // if(this.exams[e]!=undefined) return;
                    // Se suma el examen iterado de otro modulo
                    exams[e] = this.course.modules[mId].exams[e];
                });
            });
            // /*eslint-disable*/
            // console.log(JSON.parse(JSON.stringify( {...this.exams,...exams})));
            // return;

            Object.keys(exams).forEach((eId)=>{
                console.log(eId);
                if(exams[eId].categories!=undefined){
                    exams[eId].categories.forEach((c)=>{
                        if(!all_categories.length){ 
                            all_categories.push(...exams[eId].categories)
                        } else {
                            let exist = all_categories.find((cat)=>cat==c);
                            if(!exist) all_categories.push(c);
                        }
                    })
                }
            });

            
            // console.log(JSON.parse(JSON.stringify( all_categories)));
            // return;
            // /*eslint-disable*/

            // console.log(this.course.fireId);

             console.log("DONDE GUARDA?")
            console.log('/courses/'+this.course.fireId)
           

            firebase.database().ref(`courses/${this.course.fireId}/modules/${this.module.fireId}/exams`).update(this.exams).then(()=>{
                console.info('%c✓ updated exams','color:#0f0');

                firebase.database().ref(`courses/${this.course.fireId}`).update({categories:all_categories}).then(()=>{
                    console.info('%c✓ exams categories updated','color:#0f0');
                });

                

                this.$emit('end-to-course',this.course.fireId);

            }).catch((error)=>console.error(error));
        },
        isValid(){
            let valid = true;
            Object.keys(this.exams).forEach((examId)=>{
                this.error.exam[examId].title = '';
                if(isEmpty(this.exams[examId].title)){
                    valid = false;
                    this.error.exam[examId].title = 'El título del examen es obligatorio.';
                }
                // Check preguntas
                this.error.exam[examId].questionsLength = '';
                if (!Object.keys(this.exams[examId].questions).length) {
                    valid = false;
                    this.error.exam[examId].questionsLength = 'El examen debe tener preguntas, agregué preguntas y respuestas.';
                }
                // Check respuestas
                Object.keys(this.exams[examId].questions).forEach((questionId)=>{
                    // Enunciado de la pregunta
                    this.error.exam[examId].questions[questionId].statement = '';
                    if(isEmpty(this.exams[examId].questions[questionId].statement)){
                        valid = false;
                        this.error.exam[examId].questions[questionId].statement = 'El enunciado de la pregunta es obligatorio.';
                    }
                    // cantidad de respuestas
                    this.error.exam[examId].questions[questionId].answers = '';
                    if(this.exams[examId].questions[questionId].answers.length < 2){
                        valid = false;
                        this.error.exam[examId].questions[questionId].answers = 'Al menos 2 respuestas son obligatorias.'
                    }else{
                        // Respuestas sin verdadero y respuestas vacias
                        this.error.exam[examId].questions[questionId].answers = '';
                        let error = '';
                        let corrects = 0;
                        // console.log(this.exams[examId].questions[questionId].answers);
                        this.exams[examId].questions[questionId].answers.forEach((answer)=>{
                            
                            if(answer.correct) corrects++;
                            if(isEmpty(answer.label) && isEmpty(answer.image)){
                                error += 'Complete la respuesta vacia por favor. ';
                                valid = false;
                            }
                        })

                        if(corrects<1){
                            error+='Debe existir al menos una respuesta correcta. ';
                            valid = false;
                        }

                        if (error.length) this.error.exam[examId].questions[questionId].answers = error;
                    }


                    
                })
            });

            return valid;
        }
    }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect-blue {
    --ms-tag-bg: #DBEAFE;
    --ms-tag-color: #2563EB;
    --ms-ring-color: #2563EB20;
    
    --ms-tag-font-size: 0.8rem;

    --ms-border-color: #282f3ae6;
    --ms-py: 0.375rem;
    --ms-px: 0.75rem;

    --ms-option-font-size: .8rem;
    
    --ms-dropdown-border-color: #282f3ae6;
}


/*
input[type="file"] {
  display: none;
}
*/

.custom-file-upload input[type="file"] {
    display: block;
    opacity: 0;
    width: 113px;
    position: absolute;
    padding: 10px;
    cursor: pointer;
}

.custom-file-upload label {
  display: inline-block;
  padding: 12px 30px;
  background-color: #3498db;
  color: #fff;
  font-size: 1.2em;
  border-radius: 4px;
  cursor: pointer;
}

.custom-file-upload label:hover {
  background-color: #2980b9;
}

.image-box img {
    height: 132px;
    display: block;
    width: 79%;
    object-fit: cover;
    border-radius: 4px;
}

.image-box .btn-primary {
    width: 20%;
    height: 132px;
}
.image-box .btn-danger {
    position: absolute;
    right: 32px;
}

.image-box .no-image {
    height: 132px;
    width: 79%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-image svg {
    width: 50px;
    height: 50px;
    opacity: .2;
}

.answer-image-box {
    width: 86%;
    height: 80px;
}

.answer-image-box .no-image {
    width: 100%;
    height: 100%;
    border-radius: 6px;

    display: flex;
    align-items: center;
    justify-content: center;
}
.answer-image-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
}
.answer-image-box button {
    position: absolute;
    transform: translate(-42px,24px);
}
.answer-image-box button svg{
    bottom: -0.4px !important;
}


.form-check-input {
    margin-top: 0;
}

.form-check .form-check-label {
    margin-left: .75rem;
    margin-bottom: 0;
}

.form-check {
    margin-top: 0;
    margin-bottom: 0;
}
</style>