<template>
  <div class="row">
      <div class="col">
        <h4 class="mb-4">Nuevo grupo</h4>
        <form>
            <div class="form-row mb-4">
                <div class="col-12 mb-3">
                    <label class="form-label">Nombre</label>
                    <input type="text" class="form-control" v-model="group.title">
                    <div class="text-danger mt-1" v-if="error.title">{{error.title}}</div>
                </div>
                <div class="col-12 mb-2">
                    <label class="form-label">Seleccione los puestos</label>
                    <multiselect 
                        v-model="group.categories"
                        mode="tags"
                        :close-on-select="false"
                        :searchable="true"
                        :create-option="false"
                        :options="categories"
                        class="multiselect-blue"
                        :placeholder="'Agregue sectores, puede buscar y seleccionar'"
                    ></multiselect>
                    <div class="text-danger mt-1" v-if="error.categories">{{error.categories}}</div>
                </div>
            </div>

            <div class="form-row">
                <div class="col text-right">
                    <div class="btn btn-md btn-success" @click.prevent="submit">Crear grupo</div>
                </div>
            </div>
        </form>
      </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
import Multiselect from '@vueform/multiselect'
import {isEmpty} from '@/utils';

export default {
    name:'CreateGroup',
    props:{groups:Object,categories:Object},
    components:{Multiselect},
    data() {
        return {
            group:{
                title:null,
                categories:[],
            },
            validProps:['title','categories'],
            error:{
                title:null,
                categories:null,
            },
        }
    }, 
    mounted() {
        
    },
    methods:{
        submit(e){
             e.target.innerHTML = `
                <div class="spinner-border" style="width:12px;height:12px" role="status">
                    <span class="sr-only">Loading...</span>
                </div> Creando
            `
            e.target.disabled = true;

            for (let input of document.querySelectorAll('form input,form select,form textarea,form button')) {
                input.disabled = true;
            }

            if(!this.isValid()){
                e.target.innerHTML = 'Crear grupo';
                e.target.disabled = true;
                for (let input of document.querySelectorAll('form input,form select,form textarea,form button')) {
                    input.disabled = false;
                }

                return; // Exit cuz the form is invalid
            }

            /**
             * The form is valid
             */

            firebase.database().ref(`/groups`).push(this.group).then(()=>{
                console.info('%c✓ group added','color:#0f0');
                this.$emit('end',true);
            })
        },
        isValid(){
            let valid = true;
            this.validProps.forEach((prop) => {
                this.error[prop] = '';
                if (isEmpty(this.group[prop])) {
                    valid = false;
                    this.error[prop] = 'No puede estar vacio';
                }
            });
            return valid
        },
    }     
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect-blue {
    --ms-tag-bg: #DBEAFE;
    --ms-tag-color: #2563EB;
    --ms-tag-font-size: 0.8rem;

    --ms-border-color: #282f3ae6;
    --ms-py: 0.375rem;
    --ms-px: 0.75rem;

    --ms-option-font-size: .8rem;
    
    --ms-dropdown-border-color: #282f3ae6;
}
</style>