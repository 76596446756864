<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container py-1">
          <div class="modal-body mb-0">
            <div class="btn btn-light close-button" @click.prevent="$emit('close')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-circle"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                Cerrar
            </div>
            <slot name="header"></slot>
            <slot name="body"></slot>
            <!-- <div class="bg-light p-3 mt-2 rounded"> -->
            </div>
          </div>
          <!-- Buttons footer -->
          <!-- <div class="modal-footer border-top-0">
            <button
              type="button"
              @click.prevent="$emit('eleccion', false)"
              class="btn btn-light mr-2"
            >
              Cancelar
            </button>
            <button
              type="button"
              @click.prevent="$emit('eleccion', true)"
              class="btn btn-danger"
            >
              Eliminar
            </button> -->
          </div>
        </div>
      <!-- </div> -->
    <!-- </div> -->
  </transition>
</template>

<script>
//import firebase from "firebase/app";
//import "firebase/database";

export default {
  name: "ModalResource",
//   props: {resource:Object},
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>


.close-button {
    position: absolute;
    top: 0;
    right: 0;
}

.form-control {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.12rem 1.75rem 0.12rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

mark {
  background: none;
  text-decoration: underline red 3px;
}

.input-error {
  border-color: red;
}

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 60%;
  margin: 0px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 8px #00000016;
  transition: all 0.3s ease;
  font-size: 16px;

  /* max-height: 800px;
    overflow-y: scroll; */
}

.modal-body {
  margin: 20px 0;
}

.modal-body select {
  margin-bottom: 1rem;
}

input,
select {
  font-size: 16px;
}

.modal-default-button {
  float: right;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.modal-mask {
    cursor:default !important;
}
</style>
