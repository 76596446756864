<template>
  <div class="page-content">
    <!-- Navegación superior tipo breadcrumb -->
    <nav class="page-breadcrumb mb-2" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
          <router-link :to="{ name: 'Dashboard' }" class="text-dark">Inicio</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ view.title }}
        </li>
      </ol>
    </nav>

    <!-- Card title section -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h3 class=" mb-0">{{ capitalizeR(view.title) }}</h3>
            <div class="d-flex-center-between flex-wrap flex-md-nowrap">
              <p class="mt-1">{{view.description}}</p>
              <div class="mt-1 w-50 text-left text-md-right" v-if="!trigger.createModule && !trigger.listModules && !trigger.examsManager">
                <button class="btn btn-light mr-2" v-if="trigger.create || trigger.update" @click.prevent="refreshTriggers('list')">Cancelar</button>
                <div v-else>
                  <button type="button" class="btn btn-primary" disabled v-if="!courses">Obteniendo {{view.title.toLowerCase()}}</button>
                  <button type="button" class="btn btn-primary" v-else @click.prevent="refreshTriggers('create')">
                    Crear {{view.title.toLowerCase()}}
                  </button>
                </div>
              </div>
              <div class="w-50 text-left text-md-right" v-else>
                <button class="btn btn-primary" @click.prevent="refreshTriggers('list')">Lista de cursos</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- Create course -->
    <div class="row" v-if="trigger.create">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <create :courses="courses" @end="handleEnd($event)"></create>
          </div>
        </div>
      </div>
    </div>
    <!-- Create module -->
    <div class="row" v-if="trigger.createModule">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <create-module :course="selection" 
            @end-to-course="updateAndList('listModules',$event)" 
            @return="handleEvent('listModules',$event)"
            ></create-module>
          </div>
        </div>
      </div>
    </div>
    <!-- List courses -->
    <div class="row" v-if="trigger.list">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <list :courses="courses" 
              @update="handleEvent('update',$event)"
              @delete="handleEvent('delete',$event)"
              @modules="handleEvent('listModules',$event)"
              @update-list="updateAndList()"
            ></list>
          </div>
        </div>
      </div>
    </div>

    <!-- List modules -->
    <div class="row" v-if="trigger.listModules">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <list-modules 
              :course="selection" 
              :modules="(selection.modules!=undefined)?selection.modules:[]"
              @end="handleEnd($event)"
              @create-module="handleEvent('createModule',$event)"
              @update="handleEvent('updateModule',$event)"
              @exams="handleEvent('examsManager',$event)"
              @update-list="updateAndList('listModules',$event)"
            ></list-modules>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="trigger.update">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <update 
                :trivias="trivias" 
                :selected="selection"
                @edit="handleEvent('update',$event)" 
                @end="handleEnd($event)"
            >
            </update>
          </div>
        </div>
      </div>
    </div>

    <!-- update module -->
    <div class="row" v-if="trigger.updateModule">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <update-module 
            :course="selection.course" 
            :selected="selection.module" 
            @end-to-course="updateAndList('listModules',$event)" 
            @return="handleEvent('listModules',$event)"></update-module>
          </div>
        </div>
      </div>
    </div>

    <!-- Manage exams -->
    <div class="row" v-if="trigger.examsManager">
      <div class="col">
        <manage-exams 
        :course="selection.course" 
        :module="selection.module" 
        @end="handleEnd($event)" 
        @end-to-course="updateAndList('listModules',$event)" 
        @return="handleEvent('listModules',$event)"></manage-exams>
      </div>
    </div>


  </div>
</template>

<script>
import {getDataFrom,capitalize} from '@/utils';

import Create from '@/components/Course/CreateCourse.vue';
import List from '@/components/Course/ListCourses.vue';
import Update from '@/components/Course/UpdateCourse.vue';
import ListModules from '@/components/Course/Modules/ListModules.vue';
import CreateModule from '@/components/Course/Modules/CreateModule.vue';
import UpdateModule from '@/components/Course/Modules/UpdateModule.vue';
import ManageExams from '@/components/Course/Modules/ManageExams.vue';

export default {
  name: "Courses",
  components:{
    Create,
    List,
    Update,
    ListModules,
    CreateModule,
    UpdateModule,
    ManageExams
  },
  data(){
    return {
      view:{title:'Cursos',description:'Agregue y modifique cursos, gestione sus exámenes y el contenido de cada módulo.'},
      entity:'courses',

      selection:null,
      courses:null,
      trigger:{
        create:false,
        update:false,
        list:false,
        listModules:false,
        createModule:false,
        updateModule:false,
        examsManager:false,
      }
    }
  },
  mounted(){
      this.updateAndList();
  },
  methods:{
    refreshTriggers(active) {
        Object.keys(this.trigger).forEach((t) => { this.trigger[t] = false });
        if(active!=undefined) this.trigger[active] = true
    },
    handleEvent(action,selection){
      this.selection = selection;
      this.refreshTriggers(action);
    },
    updateAndList(action,selection){
      this.refreshTriggers();
      getDataFrom(this.entity).then((data)=>{
          this[this.entity] = (data) ? data : [];
          if(action!=undefined && selection!=undefined){
            this.handleEvent(action,{...this.courses[selection],...{fireId:selection}});
          }else {
            this.refreshTriggers('list');
          }
      }).catch((err)=>{ console.error(err); alert(`Ocurrio un error al obtener ${this.entity}`); });
    },
    handleEnd(update){
      if (update) {
        this.updateAndList()
      }else {
        this.refreshTriggers('list')
      }
    },
    capitalizeR(t){return capitalize(t)}
  }
}
</script>

<style>

</style>