<template>
  <div class="row">
      <div class="col">
        <div class="d-flex-center-between mb-3">
            <h4 class="">Nuevo módulo en curso: {{course.title}}</h4>
            <button class="btn btn-light" @click.prevent="$emit('return',course)">&larr; Lista de módulos</button>
        </div>
        <div class="alert alert-info custom-alert custom-alert-info mb-4">
            Luego podrá agregar evaluaciónes a este módulo. Aquí puede definir el contenido y la información básica. En las evaluaciónes es posible seleccionar una categoría o area del personal a la cual va dirigida la evaluación y por consiguiente el módulo.
        </div>
        <form>
            <div class="form-row mb-4">
                <div class="col-12 mb-3">
                    <label class="form-label">Título del módulo*</label>
                    <input type="text" class="form-control" v-model="module.title">
                    <div class="text-danger mt-1" v-if="error.title">{{error.title}}</div>
                </div>

                <div class="col-12 mb-3 mt-1">
                    <h5>Contenido del curso</h5>
                </div>

                <div class="col-12 mb-3">
                    <label class="form-label">Introducción</label>
                    <textarea class="form-control" style="height:42px !important" v-model="module.intro"></textarea>
                    <!-- <div class="text-danger mt-1" v-if="error.description">{{error.description}}</div> -->
                </div>
                <div class="col-12 mb-3">
                    <label class="form-label">Video URL</label>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="httos://www...." v-model="module.video_url">
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16"> <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/> <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/> </svg>
                            </span>
                        </div>
                    </div>
                    <!-- <div class="text-danger mt-1" v-if="error.title">{{error.title}}</div> -->
                </div>

                <div class="col-12  mb-3">
                    <input type="file" accept="application/pdf" id="input-file" @change="handleFileUpload($event)"/>

                    <div class="form-label">Contenido en PDF</div>
                    <small class="text-danger" v-if="error.file">&otimes; {error.file}</small>
                    <div class="d-flex-center-between image-box">
                        <button class="btn btn-primary" @click.prevent="openFileDialog()">Seleccionar archivo PDF</button>
                        <div class="file-data" v-if="file.name">
                            <div class="w-100">{{file.name}} <div class="badge badge-light ml-2">{{file.size}} kb</div></div>
                            <div class="progress w-50 mt-3" style="height: 4px;">
                                <div class="progress-bar" role="progressbar" :style="{width: file.load + '%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div class="bg-light no-image" v-else @click.prevent="openFileDialog()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"/> </svg>
                        </div>
                        <button class="btn btn-danger rounded-circle p-2" @click.prevent="removeFile()" v-if="file.load>99">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16"> <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/> </svg>
                        </button>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <label class="form-label">Contenido en imágenes</label>
                    <input-file :titulo="'Seleccionar imágenes'" :pdf="false" @uploading="trigger.uploading = $event" :multiple='true' :error="{estado: false,mensaje:''}" @seleccion="handleFiles($event)"></input-file>
                </div>
            </div>
            <div class="form-row">
                <div class="col text-right">
                    <button class="btn btn-md btn-success" @click.prevent="submit" :disabled="trigger.uploading">Crear módulo</button>
                </div>
            </div>
        </form>
      </div>
  </div>
</template>



<script>
import firebase from "firebase/app";
import "firebase/database";
import 'firebase/storage';
import {isEmpty, setProp
// storageFile 
} from '@/utils';
import InputFile from '@/components/InputFile.vue'
export default {
    name:'CreateModule',
    props:{course:Object},
    components:{InputFile},
    data() {
        return {
            module:{
                title:null,
                intro:null,
                video_url:null,
                file_url:null,
                images:[],
            },
            validProps:['title'],
            error:{
                title:null,
                file:null,
            },
            file:{name:null,load:0,size:null},
            trigger:{uploading:false},
        }
    }, 
    methods:{
        removeFile(){this.module.file_url = null;this.file={name:null,load:0,size:null}},
        openFileDialog() { document.getElementById('input-file').click() },
        handleFileUpload(e){
            console.log(e);
            let file = e.target.files[0];

            this.error.file = null;
            if (file.type != 'application/pdf') {
                this.error.file = 'Solo es posible cargar PDF en este campo.';
                return null;
            }

            this.file.name = file.name;
            this.file.size = (file.size/1024).toFixed(2);

            // Set name with normalized title like (example_title_1)
            let name = (this.module.title) ? this.module.title.trim().replace(/[^a-z0-9]/gi,'_') : file.name.replace(/[^a-z0-9]/gi,'_');
            // if(!name) name = file.name.replace(/[^a-z0-9]/gi,'_');
            // Add the timestamp
            name += '_' + Date.now();
            // Add the file extension
            name += '.' + file.name.split('.').pop();

            // console.log(name);

            var uploadTask = firebase.storage().ref().child(`capacitacion/files/${name}`).put(file);

            uploadTask.on('state_changed',
                (snapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    this.file.load = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + this.file.load  + '% done');
                },
                (error) => {
                    alert('Hubo un error al subir el PDF');
                    console.error(error);
                },
                () => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        this.module.file_url = downloadURL;
                    console.log('File available at', downloadURL);
                    document.getElementsByClassName('progress-bar')[0].style.background = '#10b759';
                    });
                }
            );
        },
        handleFiles(images){
            this.module.images = images;
            this.trigger.uploading = false;
        },
        submit(e){
             e.target.innerHTML = `
                <div class="spinner-border" style="width:12px;height:12px" role="status">
                    <span class="sr-only">Loading...</span>
                </div> Creando
            `
            e.target.disabled = true;

            for (let input of document.querySelectorAll('form input,form select,form textarea,form button')) {
                input.disabled = true;
            }

            if(!this.isValid()){
                // Change the button state
                e.target.innerHTML = 'Crear módulo';
                e.target.disabled = false;
                for (let input of document.querySelectorAll('form input,form select,form textarea,form button')) {
                    input.disabled = false;
                }
                // Exit cuz invalid form
                return;
            }

            /**
             * The form is valid
             */

            // Set the order property
            let order = 0;
            if(this.course.modules != undefined) order = Object.keys(this.course.modules).length;
            setProp(this.module,'order',order);
            

            firebase.database().ref(`/courses/${this.course.fireId}/modules`).push(this.module).then(()=>{
                console.info('%c✓ module added','color:#0f0');
                this.$emit('end-to-course',this.course.fireId);
            })
        },
        isValid(){
            let valid = true;
            this.validProps.forEach((prop) => {
                this.error[prop] = '';
                if (isEmpty(this.module[prop])) {
                    valid = false;
                    this.error[prop] = 'No puede estar vacio';
                }
            });
            return valid
        },
    }     
}
</script>

<style scoped>


input[type="file"] {
  display: none;
}

.image-box .file-data {
    height: 80px;
    display: flex;
    width: 79%;
    border-radius: 4px;
    border: 1px solid #000;
    justify-content: center;
    padding-left: 1rem;
    flex-flow: wrap column;
}

/* .image-box .progress {

} */

.image-box .btn-primary {
    width: 20%;
    height: 80px;
}
.image-box .btn-danger {
    position: absolute;
    right: 32px;
}

.image-box .no-image {
    height: 80px;
    width: 79%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-image svg {
    width: 50px;
    height: 50px;
    opacity: .2;
}
</style>