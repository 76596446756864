<template>
    <div>
        <div class="d-flex-center-between mb-4">
            <h4 class="">Modulos del curso: {{course.title}}</h4>
            <div v-if="!trigger.ordering">
                <button class="btn btn-primary mr-2" @click.prevent="trigger.ordering = true">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z"/> </svg>
                    Ordenar módulos
                </button>
                <button class="btn btn-primary" @click.prevent="$emit('create-module',course)">
                    <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>
                    Nuevo modulo
                </button>
            </div>
            <div v-if="trigger.ordering">
                <button class="btn btn-light" @click="trigger.ordering = false">← Cancelar</button>
            </div>
        </div>
        <div class="table-responsive" v-if="!trigger.ordering">
            <table id="datatable" class="table">
                <thead>
                    <tr>
                        <th hidden></th>
                        <th>Nombre</th>
                        <th>Video</th>
                        <th>PDF</th>
                        <th>Galeria</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(mod, i) in modules" :key="i">
                        <td hidden>{{mod.order}}</td>
                        <td>{{mod.title}}</td>
                        <td>
                            <span class="badge badge-light" v-if="mod.video_url==undefined"> - </span>
                            <a class="badge badge-primary" v-else :href="mod.video_url" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16"> <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/> <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/> </svg>
                                Ver
                            </a>
                        </td>
                        <td>
                            <span class="badge badge-light" v-if="mod.file_url==undefined"> - </span>
                            <a class="badge badge-primary" v-else :href="mod.file_url" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16"> <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/> <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/> </svg>
                                Ver
                            </a>
                        </td>
                        <td>
                            <span class="badge badge-light" v-if="mod.images==undefined"> - </span>
                            <span class="badge badge-primary" v-else>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>
                                {{mod.images.length}}
                            </span>
                        </td>
                       
                        <td>
                            <div class="btn btn btn-primary ml-2" @click.prevent="$emit('exams',{module:{...mod,...{fireId:i}},course:course})">
                                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><polyline points="17 11 19 13 23 9"></polyline></svg>
                                Exámenes
                            </div>
                            <div class="btn btn btn-primary ml-2" @click.prevent="$emit('update',{module:{...mod,...{fireId:i}},course:course})">
                                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg>
                                Modificar módulo
                            </div>
                            <div class="btn btn btn-danger ml-2" @click.prevent="handleDelete(course.fireId,i)">
                                <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                                Eliminar módulo
                            </div>
                            <!-- <div class="btn btn btn-warning ml-2" @click.prevent="checkIt(i)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bug-fill" viewBox="0 0 16 16"> <path d="M4.978.855a.5.5 0 1 0-.956.29l.41 1.352A4.985 4.985 0 0 0 3 6h10a4.985 4.985 0 0 0-1.432-3.503l.41-1.352a.5.5 0 1 0-.956-.29l-.291.956A4.978 4.978 0 0 0 8 1a4.979 4.979 0 0 0-2.731.811l-.29-.956z"/> <path d="M13 6v1H8.5v8.975A5 5 0 0 0 13 11h.5a.5.5 0 0 1 .5.5v.5a.5.5 0 1 0 1 0v-.5a1.5 1.5 0 0 0-1.5-1.5H13V9h1.5a.5.5 0 0 0 0-1H13V7h.5A1.5 1.5 0 0 0 15 5.5V5a.5.5 0 0 0-1 0v.5a.5.5 0 0 1-.5.5H13zm-5.5 9.975V7H3V6h-.5a.5.5 0 0 1-.5-.5V5a.5.5 0 0 0-1 0v.5A1.5 1.5 0 0 0 2.5 7H3v1H1.5a.5.5 0 0 0 0 1H3v1h-.5A1.5 1.5 0 0 0 1 11.5v.5a.5.5 0 1 0 1 0v-.5a.5.5 0 0 1 .5-.5H3a5 5 0 0 0 4.5 4.975z"/> </svg>
                                Check
                            </div> -->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <order-modules :modules="modules" :course_id="course.fireId" @end="handleOrder(course.fireId)" v-if="trigger.ordering"></order-modules>
    </div>
</template>

<script>
import { tableConfig } from "@/env";
import $ from 'jquery'
import Swal from "sweetalert2";

import OrderModules from './OrderModules.vue';

import firebase from "firebase/app";
import "firebase/database";

export default {
    name:'ListCourses',
    props: {modules:Object,course:Object},
    components:{OrderModules},
    data() {
        return {
            trigger:{ordering:false}
        }
    },
    methods: {
        handleDelete(courseId,moduleId){
            Swal.fire({
                title: '¿Vas a eliminar el modulo?',
                text: "Cuidado! Esta acción no se puede deshacer",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar!'
            }).then((result) => {
                if (result.isConfirmed) {
                    firebase.database().ref(`courses/${courseId}/modules/${moduleId}`).remove().then(()=>{
                        let exams = []
             
                        Object.keys(this.modules).forEach((mId)=>{
                            if(mId==moduleId) return;
                            if(this.modules[mId].exams == undefined) return;

                            Object.keys(this.modules[mId].exams).forEach(e => {
                                exams.push(this.modules[mId].exams[e])
                            });
                        });


                        let all_categories = [];
                        // se guardan todas las categorias en el nivel de course
                        Object.keys(exams).forEach((eId)=>{
                            if(exams[eId].categories!=undefined){
                                exams[eId].categories.forEach((c)=>{
                                    if(!all_categories.length){ 
                                        all_categories.push(...exams[eId].categories)
                                    } else {
                                        let exist = all_categories.find((cat)=>cat==c);
                                        if(!exist) all_categories.push(c);
                                    }
                                })
                            }
                        });

                        firebase.database().ref(`courses/${courseId}`).update({categories:all_categories}).then(()=>{
                            console.info('%c✓ exams categories updated','color:#0f0');

                            // Order the remaining modules
                            let ordered = [];
                            let withOrder = [];

                            Object.keys(this.modules).forEach((fId)=>{
                                if(fId === moduleId) return;

                                if(this.modules[fId].order != undefined) {
                                    withOrder.push({...this.modules[fId],...{fireId:fId}});
                                }else {
                                    ordered.push({...this.modules[fId],...{fireId:fId}});
                                }
                            });

                            withOrder.forEach((e)=>{
                                // console.log('ord',e);
                                // console.log('obj',ordered[e.order]);

                                let messy;
                                if(ordered[e.order]!=undefined) messy = JSON.parse(JSON.stringify(ordered[e.order]));
                                
                                ordered[e.order] = e;
                                if(messy!==undefined) ordered.push(messy);
                            });


                            // Remove the null elements
                            ordered = ordered.filter((e)=>e!=null);


                            // Change the order property to array index property
                            ordered.forEach((e,i)=>{
                                // if(e.order===undefined) e.order = i;
                                
                                e.order = i;
                            });

                            let updatedModules = {};
                            ordered.forEach((m)=>{
                                updatedModules[m.fireId] = m;
                                delete updatedModules[m.fireId].fireId;
                            });

                            firebase.database().ref(`courses/${courseId}/modules`).update(updatedModules).then(()=>{
                                console.log('%c✓ Ordered modules updated','color:#0f0');
                                
                                Swal.fire(
                                    'Todo listo!',
                                    'El modulo fué eliminado.',
                                    'success'
                                )
                                // this.updateAndList();
                                this.$emit('update-list',courseId);

                            }).catch((e)=>{console.error(e);alert('Hubo un error al guardar los modulos, actualizaremos la página.');document.location.reload()});
                        });
                    })
                }
            })
        },
        handleOrder(courseId){
            this.trigger.ordering = false;
            this.$emit('update-list',courseId);
        }
    },
    mounted(){
        $('#datatable').dataTable().fnDestroy();
        let c = tableConfig;
        c.buttons = [];
        c.searching = false;
        c.paging = false;
        c.info = false;
        c.emptyTable = 'No hay modulos, agregá nuevos para crear exámenes y contenido';

        c.order = [0, 'asc'],

        $('#datatable').DataTable(tableConfig);
    }
}
</script>

<style>

</style>