<template>
    <div class="mb-2 d-flex align-items-center justify-content-between">
        <h4 class="">
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path><polyline points="13 2 13 9 20 9"></polyline></svg>
            Evaluaciones de {{handleFormat('capitalizeEach',employee.nombre)}}
        </h4>
        <buttton class="btn btn-light" @click.prevent="$emit('ended',false)">&larr; Regresar</buttton>
    </div>
    <div class="table-responsive" v-if="trigger.list">
        <table :id="tableId" class="table">
            <thead>
                <tr>
                    <th>Curso</th>
                    <th>Módulo</th>
                    <th>Examen</th>
                    <th>Mejor nota</th>
                    <th>Intentos</th>
                </tr>
            </thead>
            <tbody>
                <!-- <template v-for="course,c in employee.courses" :key="c">
                    
                </template> -->
                <tr v-for="exam,c in evaluations" :key="c">
                    <td>{{exam.course}}</td>
                    <td>{{exam.module}}</td>
                    <td>{{exam.exam}}</td>
                    <td><div class="badge badge-light">{{exam.best_attemp}}</div></td>
                    <td>
                        <div class="badge badge-light">{{exam.attemps.length}}</div> 
                        <button class="badge badge-primary border-0 ml-2" @click="detailOfAttempts(exam)">
                            Ver
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    
    <transition name="modal" v-if="trigger.attemps" @click="detailOfAttempts()">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container py-1">
                    <div class="modal-body mb-0">
                        <h4 class="mb-2">{{selection.course}}</h4>
                        <table class="table table-bordered">
                            <tbody>
                                <tr>
                                    <td>Modulo: {{selection.module}}</td>
                                    <td>Examen: {{selection.exam}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-bordered">
                            <tbody>
                                <tr>
                                    <td>Intentos:</td>
                                    <td v-for="attemp,a in selection.attemps" :key="a">{{parseInt(attemp.porcentaje)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </transition>



</template>

<script>
import { tableConfig } from "@/env"
import { capitalize,dateFormat, capitalizeEach } from "@/utils";
import $ from 'jquery'

export default {
    name:'EmployeeEvaluated',
    props: {
        employee:Object,
        courses:Object
    },
    data() {
        return {
            tableId:this.getRandomId('dt'),
            trigger:{list:false,attemps:false},
            evaluations:null,
            selection:null,
        }
    },
    methods:{
        getRandomId(t){
            return t+(Math.random()).toString(16).substring(2);
        },
        handleFormat(format,data){
            if (format==undefined) return data;
            
            if(format=='capitalize') return capitalize(data);
            if(format=='capitalizeEach') return capitalizeEach(data);
            if(format=='date') return dateFormat(data);

            return data;
        },
        detailOfAttempts(exam){
            if(exam != undefined){
                this.selection = exam;
                this.trigger.attemps = true;
            } else {
                this.selection = null;
                this.trigger.attemps = false;
            }
        }
    },
    mounted(){
        this.evaluations = [];
        // Se iteran los cursos del empleado
        Object.keys(this.employee.cursos).forEach((c)=>{
            let curso = this.employee.cursos[c];
            // Se iteran modulos del curso
            Object.keys(curso).forEach((m)=>{
                // Se itera ssobre los examenes
                Object.keys(curso[m]).forEach((e)=>{
                    let bestNote = 0;
                    curso[m][e].forEach((n)=>{
                        if(n.porcentaje>bestNote){
                            bestNote=n.porcentaje;
                        }
                    })

                    this.evaluations.push({
                        course:this.courses[c].title,
                        module:this.courses[c].modules[m].title,
                        exam:this.courses[c].modules[m].exams[e].title,
                        attemps: curso[m][e],
                        best_attemp: bestNote
                    })
                })
            })
        });

        // console.log('eva',evaluaciones);
        // this.data = evaluaciones;

        this.trigger.list=true;

        $('#'+this.tableId).dataTable().fnDestroy();
        let config = tableConfig;
        config.buttons= [
            'copy',
            'excel',
            'csv',
            'pdf'
        ];

        this.$nextTick(()=>{
            $('#'+this.tableId).DataTable(config);
            this.$forceUpdate();
        })


    }
}
</script>

<style scoped>

.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  cursor:not-allowed
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 60%;
  margin: 0px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 8px #00000016;
  transition: all 0.3s ease;
  font-size: 16px;
}
</style>