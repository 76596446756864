<template>
    <h4 class="table-title">{{title}}</h4>
    <div class="table-responsive">
        <table :id="tableId" class="table">
            <thead>
                <tr>
                    <th v-for="(column,c) in columns" :key="c">{{column.label}}</th>
                    <th v-if="actions!=undefined && actions!=null && actions.length"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(d, i) in data" :key="i" >
                    <td v-for="(column,c) in columns" :key="c">
                        <template v-if="column.type == undefined">{{handleFormat(column.format,d[column.trackBy])}}</template>
                        <div v-else-if="column.type == 'badge'" class="badge badge-light">{{handleFormat(column.format,d[column.trackBy])}}</div>
                        <div v-else-if="column.type == 'length'" class="badge badge-light">{{Object.keys(d[column.trackBy]).length}}</div>
                    </td>
                    <td>
                        <button v-for="(action,a) in actions" :key="a" class="btn" :class="'btn-'+action.color" @click="$emit(action.emitter,d)">
                            {{action.label}}
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { tableConfig } from "@/env"
import { capitalize,dateFormat } from "@/utils";
import $ from 'jquery'

export default {
    name:'List',
    props: {
        data:Object,
        columns:Object, // format(c)=> label: title of col, trackBy: name of data prop, type: badge,date
        title:String,
        actions:Boolean
    },
    data() {
        return {
            tableId:this.getRandomId('dt'),
        }
    },
    methods:{
        getRandomId(t){
            return t+(Math.random()).toString(16).substring(2);
        },
        handleFormat(format,data){
            if (format==undefined) return data;
            
            if(format=='capitalize') return capitalize(data);
            if(format=='date') return dateFormat(data);

            return data;
        }
    },
    mounted(){
        $('#'+this.tableId).dataTable().fnDestroy();
        let config = tableConfig;
        config.buttons = [];

        this.$nextTick(()=>{
            $('#'+this.tableId).DataTable(config);
            this.$forceUpdate();
        })


    }
}
</script>

<style>

</style>