<template>
  <div class="page-content">
    <!-- Navegación superior tipo breadcrumb -->
    <nav class="page-breadcrumb mb-2" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
          <router-link :to="{ name: 'Dashboard' }" class="text-dark">Inicio</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Reporte de evaluaciones
        </li>
      </ol>
    </nav>
    <!-- List -->
    <div class="row" v-if="trigger.list">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body pt-2">
            <list
              title="Lista de personal evaluado"
              :data="personal"
              :columns="[
                {label:'Documento',trackBy:'dni'},
                {label:'Nombre',trackBy:'nombre',format:'capitalize'},
                {label:'Legajo',trackBy:'legajo'},
                {label:'Categoría',trackBy:'categoria',type:'badge',format:'capitalize'},
                {label:'Cursos',trackBy:'cursos',type:'length'},
              ]"
              :actions="[{color:'primary',label:'Ver cursos',emitter:'employee-detail'}]"
              @employee-detail="handleEvent('employeeDetail',$event)"
            ></list>
          </div>
        </div>
      </div>
    </div>
    <!-- List -->
    <div class="row" v-if="trigger.employeeDetail">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <employee-evaluated
              :employee="selection"
              :courses="courses"
              @ended="refreshTriggers('list')"
            ></employee-evaluated>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import List from "@/components/List";
import { getDataFrom } from "@/utils";
import EmployeeEvaluated from '@/components/Reports/EmployeeEvaluated';
export default {
    name:'Evaluated',
    components:{List,EmployeeEvaluated},
    data() {
      return {
        trigger:{list:false,employeeDetail:false},
        personal:null,
        courses:null,
        selection:null,
      }
    },
    mounted(){
      this.personal = [];
      getDataFrom('courses',true).then((cursos)=>{
        console.log('cursos',cursos);
        this.courses = cursos;
        getDataFrom('https://aplicativos.firebaseio.com/logros.json',false).then((logros)=>{
          // console.log('logros',logros);
  
          getDataFrom('https://aplicativos.firebaseio.com/capacitacion.json',false).then((capacitaciones)=>{
            // console.log('capacitaciones',capacitaciones);
  
            getDataFrom('https://aplicativos.firebaseio.com/distribucion.json?print=pretty',false).then((distribucion)=>{
              // console.log('distri',distribucion);
  
              getDataFrom('https://aplicativos.firebaseio.com/categorias.json?print=pretty',false).then((categorias)=>{
                // console.log('categorias',categorias);
  
  
                let evaluados = [];

                Object.keys(logros).forEach((dni)=>{
                  // Se obtiene data del empleado y luego se itera por los demás cursos del mismo empleado
  
                  // Obtener primera clave para el numero de legajo
                  let firstKey = Object.keys(logros[dni]).at(0);
                  // Get categoria
                  let categoria = this.getEmployeeCategory(logros[dni][firstKey].legajo,distribucion,categorias);
  
                  // console.log(logros[dni][firstKey].nombre, 'esta en la categoría',(categoria!=null)?categoria.nombre:'Sin asignar');
                  let empleado = {
                    nombre: logros[dni][firstKey].nombre,
                    legajo: logros[dni][firstKey].legajo,
                    dni: dni,
                    categoria:(categoria!=null)?categoria.nombre:'Sin asignar',
                    cursos:{},
                  }
  
                  // console.log('empleado',empleado);

                  // Se iteran las capacitaciones, es decir las instancias de evaluacion
                  Object.keys(capacitaciones[empleado.dni]).forEach((cursoId)=>{
                    // Se itera por cursos

                    // Se comprueba si el curso existe aun, para saltearlo
                    if(cursos[cursoId]==undefined) return;


                    // console.log(empleado.dni,cursoId);
                    let evaluacion = capacitaciones[empleado.dni][cursoId];
                    // let curso = capacitaciones[empleado.dni][cursoId];
                    // console.log(curso);
                    // console.log(evaluacion);
                    // Si el curso no se inicializo
                    if(empleado.cursos[evaluacion['curso_id']] == undefined){ 
                      empleado.cursos[evaluacion['curso_id']] = {};
                      // empleado.cursos[evaluacion['curso_id']].nombre = this.courses[evaluacion['curso_id']].title;
                    }

                    // Si el modulo no se inicializo
                    // console.log(evaluacion['curso_id']);
                    if(empleado.cursos[evaluacion['curso_id']][evaluacion['modulo_id']] == undefined){
                      empleado.cursos[evaluacion['curso_id']][evaluacion['modulo_id']] = {};
                      // empleado.cursos[evaluacion['curso_id']][evaluacion['modulo_id']].nombre = this.courses[evaluacion['curso_id']].modules[evaluacion['modulo_id']].title;
                    }

                    // Se inicializa examen y se agregan examenes
                    if(empleado.cursos[evaluacion['curso_id']][evaluacion['modulo_id']][evaluacion['examen_id']] == undefined){
                      empleado.cursos[evaluacion['curso_id']][evaluacion['modulo_id']][evaluacion['examen_id']] = [];
                    }

                    if(evaluacion['intentos'] != undefined){
                      // SE agrego propiedad de tipo Object intentos
                    }else {
                      // No hay array intentos
                      // se crea una copia con JSON.parse para perder la referencia y eliminar las propiedades que no son intentos
                      let n = JSON.parse(JSON.stringify(evaluacion));
                      // Se eliminan las propiedades de la nueva evaluacion
                      delete n['curso_id'];
                      delete n['modulo_id'];
                      delete n['examen_id'];
                      // Se itera sobre los intentos
                      Object.keys(n).forEach((e)=>{
                        empleado.cursos[evaluacion['curso_id']][evaluacion['modulo_id']][evaluacion['examen_id']].push(n[e]);
                      })
                    }

                  });
                  evaluados.push(empleado);
                });

                // Ultima pasada elimina personal sin cursos (cursos que no existen ya)
                
                evaluados.forEach((empleado)=>{
                  if(Object.keys(empleado.cursos).length) this.personal.push(empleado);
                });

                this.trigger.list = true;
              })
            })
  
          });
        });
      });
    },
    methods: {
      getEmployeeCategory(legajo,distribucion,categorias){
        // Se itera sobre las operaciones/categorias
        let categoria_id = null;
        Object.keys(distribucion).some((d)=>{
          // TODO se puede mejorar obteniendo previamente las categorías del curso.
          // d representa una operacion o una categoria, acá importan las categorias.
          // Se itera sobre los empleados en una categoria
          return Object.keys(distribucion[d]).some((empleadoId)=>{
            // se busca el legajo
            if(distribucion[d][empleadoId].legajo == legajo){
              categoria_id = d;
              return true;
            } else {
              false;
            }
          })
        });
        // console.log('se busca categoria',categoria_id);
        let categoria = null;
        // Se busca la categoria correspondiente
        Object.keys(categorias).some((s)=>{
          if(categorias[s].codigo == categoria_id){
            categoria = categorias[s];
            return true;
          }
        });

        return categoria;
      },
      refreshTriggers(active) {
        Object.keys(this.trigger).forEach((t) => { this.trigger[t] = false });
        if(active!=undefined) this.trigger[active] = true
      },
      handleEvent(action,selection){
        this.selection = selection;
        this.refreshTriggers(action);
      },

      
    }
}
</script>

<style>

</style>  