import { createWebHistory, createRouter } from "vue-router";
import APP_DATA from '@/env';

import Dashboard from "@/views/Dashboard.vue";
import Login from "@/views/Login.vue";


import Courses from "@/views/Courses.vue";
import Groups from "@/views/Groups.vue";
import Resources from "@/views/Resources.vue";
import ReportEvaluated from '@/views/Reports/Evaluated.vue';
import Disapproved from '@/views/Reports/Disapproved.vue';



const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/cursos",
    name: "Courses",
    component: Courses,
  },
  {
    path: "/grupos",
    name: "Groups",
    component: Groups,
  },
  {
    path: "/resources",
    name: "Resources",
    component: Resources,
  },
  {
    path: "/reportes/evaluaciones",
    name: "ReportEvaluated",
    component: ReportEvaluated,
  },
  {
    path: "/reportes/desaprobados",
    name: "Disapproved",
    component: Disapproved,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  }, 
];

function isAuthenticated(){
  let modulo = APP_DATA.platform_module;
  if (sessionStorage.getItem('userPermisos')) {
    if (JSON.parse(sessionStorage.getItem('userPermisos')).find((record) => record.ModulosAccess === modulo)) {
      var status =  JSON.parse(sessionStorage.getItem('userPermisos')).find( record => record.ModulosAccess === modulo).Lector;
      return status;
    }else{
      return false;
    }
  }
  return false
}

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'hash',
  linkActiveClass: 'active'
});

/**
 * Es posible mejorar el middleware utilizando la propiedad meta de cada ruta, por ejemplo
 * routes = [
 *  {
 *    path: "/ruta/hacia/aca"
 *    meta: {
 *      public: true | false
 *      permission: user | admin | sudo
 *    }
 *  }
 * ]
 * 
 * De esta forma a traves del middleware es fáclmente detectable el tipo de usuario o url, así se comprueba el login o no 
 * y tambien se puede utilizar en las vistas para mostrar contenido o no
 */

router.beforeEach((to, from, next) => {
  // To bypass the login (public routes)
  // if(to.name == 'Metricas') next()
  
  if ((to.name !== 'Login') && !isAuthenticated()) next({ name: 'Login' })
  else next()
})

export default router;
