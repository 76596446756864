<template>
  <nav class="sidebar">
    <div class="sidebar-header">
      <a href="/" class="sidebar-brand"> <span>FS</span> {{app_data.name.toUpperCase()}}</a>
      <div class="sidebar-toggler not-active">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="sidebar-body">
      <ul class="nav">
        <li class="nav-item">
          <router-link :to="{ name: 'Dashboard' }" class="nav-link">
            <i class="link-icon" data-feather="home"></i>
            <span class="link-title">Inicio</span>
          </router-link>
        </li>
        <li class="nav-item nav-category">Gestión</li>
        <li class="nav-item">
          <router-link :to="{ name: 'Courses' }" class="nav-link">
            <i class="link-icon" data-feather="book-open"></i>
            <span class="link-title">Cursos</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'Groups' }" class="nav-link">
            <i class="link-icon" data-feather="package"></i>
            <span class="link-title">Grupos</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'Resources' }" class="nav-link">
            <i class="link-icon" data-feather="archive"></i>
            <span class="link-title">Recursos</span>
          </router-link>
        </li>
        
        <li class="nav-item nav-category">Reportes</li>
        <li class="nav-item">
          <!-- <router-link :to="{ name: 'ReportEvaluated' }" class="nav-link">
            <i class="link-icon" data-feather="user"></i>
            <span class="link-title">Evaluaciones</span>
          </router-link> -->
          <router-link :to="{ name: 'Disapproved' }" class="nav-link">
            <i class="link-icon" data-feather="x-circle"></i>
            <span class="link-title">Reprobados</span>
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import APP_DATA from '@/env';

export default {
  name: 'Menu',
  data() {
    return {
      app_data:APP_DATA,
    }
  },
};


</script>

<style scoped>
.sidebar .sidebar-header .sidebar-brand{
    /* font-size: 1.2rem; */
    padding-top: 4px;
}
</style>
