<template>
  <div class="page-content">
    <!-- Navegación superior tipo breadcrumb -->
    <nav class="page-breadcrumb mb-2" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">
          <router-link :to="{ name: 'Dashboard' }" class="text-dark">Inicio</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ view.title }}
        </li>
      </ol>
    </nav>

    <!-- Card title section -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h3 class=" mb-0">{{ capitalizeR(view.title) }}</h3>
            <div class="d-flex-center-between flex-wrap flex-md-nowrap">
              <p class="mb-0">{{view.description}}</p>
              <div class="text-left text-md-right d-flex">
                <button class="btn btn-outline btn-outline-primary mr-2" v-if="!trigger.categories" @click.prevent="refreshTriggers('categories')">Categorias</button>
                <button class="btn btn-outline btn-outline-light text-dark mr-2" v-else @click.prevent="refreshTriggers('list')">Cerrar categorías</button>

                <button class="btn btn-light mr-2" v-if="trigger.create || trigger.update" @click.prevent="refreshTriggers('list')">Cancelar</button>
                <div v-else>
                  <button type="button" class="btn btn-primary" @click.prevent="refreshTriggers('create')">
                    Agregar nuevo material
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- Create -->
    <div class="row" v-if="trigger.create">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <create 
              @end="handleEnd($event)"
            ></create>
          </div>
        </div>
      </div>
    </div>
    <!-- List -->
    <div class="row" v-if="trigger.list">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <list 
              :resources="resources"
              @update="handleEvent('update',$event)"
              @removed="updateAndList()"
            ></list>
          </div>
        </div>
      </div>
    </div>

    <!-- Update -->
    <div class="row" v-if="trigger.update">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <update
              :selected="selection"
              @end="handleEnd($event)"
            >
            </update>
          </div>
        </div>
      </div>
    </div>

    <!-- Categories -->
    <div class="row" v-if="trigger.categories">
      <div class="col stretch-card">
        <div class="card">
          <div class="card-body">
            <categories
              @end="handleEnd($event)"
              @update-categories="handleUpdatePartialData()"
            >
            </categories>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getDataFrom,capitalize} from '@/utils';
// import $ from "jquery";
import Swal from "sweetalert2";

import firebase from "firebase/app";
import "firebase/database";

import Create from '@/components/Resource/CreateResource.vue';
import List from '@/components/Resource/ListResources.vue';
import Update from '@/components/Resource/UpdateResource.vue';
import Categories from '@/components/Resource/CategoriesManager.vue';


export default {
  name: "Resources",
  components:{
    Create,
    List,
    Update,
    Categories
  },
  data(){
    return {
      view:{title:'Material',description:'Agregue y modifique recursos, estos sirven como material adicional.'},
      entity:'resources',

      selection:null,
      resources:null,
      categories:[],

      trigger:{
        create:false,
        update:false,
        list:false,
      }
    }
  },
  mounted(){
    this.updateAndList(); // Get groups
    //   getDataFrom('https://aplicativos.firebaseio.com/categorias.json', false).then((data)=>{

    //       this.categories = Object.keys(data).map((d)=>{
    //           return {
    //               value: data[d].codigo,
    //               label: data[d].nombre
    //           }
    //       });
    //   });
  },
  methods:{
    refreshTriggers(active) {
        Object.keys(this.trigger).forEach((t) => { this.trigger[t] = false });
        if(active!=undefined) this.trigger[active] = true
    },
    handleEvent(action,selection){
      this.selection = selection;
      this.refreshTriggers(action);
    },
    updateAndList(){
      getDataFrom(this.entity).then((data)=>{
          this[this.entity] = (data) ? data : [];
          this.refreshTriggers('list');
      }).catch((err)=>{ console.error(err); alert(`Ocurrio un error al obtener ${this.entity}`); });
    },
    handleEnd(update){
      if (update) {
        this.updateAndList()
      }else {
        this.refreshTriggers('list')
      }
    },
    handleUpdatePartialData(){
      getDataFrom(this.entity).then((data)=>{
          this[this.entity] = (data) ? data : [];
      }).catch((err)=>{ console.error(err); alert(`Ocurrio un error al obtener ${this.entity}`); });
    },
    handleDelete(fireId){
      Swal.fire({
        title: '¿Vas a eliminar el recurso?',
        text: "Cuidado! Esta acción no se puede deshacer",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          firebase.database().ref(`groups/${fireId}`).remove().then(()=>{
            Swal.fire(
              'Todo listo!',
              'El material fué eliminado.',
              'success'
            )

            this.updateAndList();
          })
        }
      })
    },
    capitalizeR(t){return capitalize(t)}
  }
}
</script>

<style>

</style>