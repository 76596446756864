export function capitalize(word) {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
}

export function capitalizeEach(string){
    let r = "";
    string.split(' ').flatMap(w => {
        if(w!="" && w.length){
            r+=capitalize(w.trim()) + " ";
        }
    });
    return r;
}

export function getUniqueId(){
    return Math.floor(Math.random() * (1000 - 9999 + 1) + 9999).toString(32) + Date.now().toString(32);
}

/**
 * Define propiedades a objetos en caso de no existir, acorta las lineas al crear estas nuevas propiedades,
 * evitanto los parametros writable, enmumerable y configurable
 * @param obj objeto al cual se le agrega la propiedad
 * @param name nombre de la nueva propiedad
 * @param val valor que se setea en la propiedad
 */
 export function setProp(obj,name,val){
    if (!Object.prototype.hasOwnProperty.call(obj, name)) {
        Object.defineProperty(obj, name, {
        value: val,
        writable: true,
        enumerable: true,
        configurable: true
        });
    }
 }


 import axios from "axios";
 import APP_DATA from "@/env";

/**
 * Devulve en una promesa la entidad consultada a través de la api
 * @param {String} entity entidad buscada siguiendo path de firebase.
 * @param {String} firebase indica si la consulta es a la base de dato del proyecto. 
 * @returns una promesa resolve (data[] o null)
 */

export function getDataFrom(entity,firebase,query){
    return new Promise((resolve, reject) =>{
        let url;
        if(firebase==undefined || firebase==true){
            // get firebase url
            url = APP_DATA.db_url[APP_DATA.env];
            // Set entity like: firebase.com/operaciones.json
            url += `/${entity}.json`;
            // If query is set, add the query: ../operaciones.json?orderBy="height"...
            if(query!=undefined && query==true) url += `?${query}`;
        }else{
            url = entity;
        }

        axios.get(url).then((response)=>{
            // console.log(response);
            if(response.status == 200){
                resolve(response.data);
            }else{ reject(null); }
        })
        .catch((error)=>{
            reject(error);
        })
    })
}

/**
 * Comprueba que el valor tenga valor y no sea null
 * @param property de un objeto
 * @returns true si es vacio
 */
 export function isEmpty(property){
    return property == null || property == '';
}
/**
 * Comprueba que la cadena dada no contenga nada mas que números
 * @param property un string a comprobar
 * @returns true si es numerico
 */
export function isNum(property){
    return /^\d+$/.test(property);
}



import firebase from "firebase/app";
import 'firebase/storage';

export function putBase64(data) {
    return new Promise((resolve,reject)=>{
        var storageRef = firebase.storage().ref().child(`capacitacion/images/${new Date().getTime()+'-'+Math.ceil(Math.random()*1000)}`);
        storageRef.putString(data, 'data_url').then(function(snapshot) {
            
            snapshot.ref.getDownloadURL().then(function(downloadURL) {
                console.log('File available at', downloadURL);
                resolve(downloadURL);
            }).catch(e=>{reject(e);})

        });  
    })
}


export function removeFile(url,filetype) {
    return new Promise((resolve,reject)=>{
        let type = 'images';

        if(filetype!==undefined && filetype.length) type = filetype;
        

        let imageId = url.split(`${type}%2F`)[1].split('?alt')[0];
        var storageRef = firebase.storage().ref(`${type}/`);
        // Create a reference to the file to delete
        var file = storageRef.child(imageId);
    
        // Delete the file
        file.delete().then(function() {
            console.log(`Archivo: ${imageId} eliminado`);
            resolve(true)
        }).catch(function(error) {
            reject(error)
        // Uh-oh, an error occurred!
        });
    })

}

export function storageFile(file,name) {
    return new Promise((resolve,reject)=>{
        var storageRef = firebase.storage().ref().child(`capacitacion/files/${name}`);
        storageRef.put(file).then((snapshot) =>{
            snapshot.ref.getDownloadURL().then((downloadURL) => {
                console.log('File available at', downloadURL);
                resolve(downloadURL);
            }).catch(e=>{reject(e);})
        });
    })
}

/**
 * Convierte la fecha dada al formato utilizado en Argentina DD-MM-AAAA
 * @param {String} initDate fecha para transformar, puede ser un Date o un string con fecha valida
 * @returns String fecha formateada
 */
 export function dateFormat(initDate,includeYear){
    let date = new Date(initDate)

    let day = date.getDate()
    let month = date.getMonth() + 1
    let year;
    if (includeYear==undefined) year = date.getFullYear()

    if(month < 10){
        if(day<10){
            return (includeYear!=undefined && !includeYear) ? (`0${day}-0${month}`) : (`0${day}-0${month}-${year}`)
        }
        return (includeYear!=undefined && !includeYear) ? (`${day}-0${month}`) : (`${day}-0${month}-${year}`)
    }else{
        if(day<10){
            return (includeYear!=undefined && !includeYear) ? (`0${day}-${month}`) : (`0${day}-${month}-${year}`)
        }
        return (includeYear!=undefined && !includeYear) ? (`${day}-${month}`) : (`${day}-${month}-${year}`)
    }
}


