<template>
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th>Orden</th>
                    <th>Nombre</th>
                    <!-- <th>Introducción</th> -->
                    <th>Video</th>
                    <th>PDF</th>
                    <th>Galeria</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(mod, i) in orderedModules" :key="i" >
                    <td>{{mod.order}}</td>
                    <td>{{mod.title}}</td>
                    <!-- <td>{{mod.intro}}</td> -->
                    <td>
                            <span class="badge badge-light" v-if="mod.video_url==undefined"> - </span>
                            <a class="badge badge-primary" v-else :href="mod.video_url" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16"> <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/> <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/> </svg>
                                Ver
                            </a>
                        </td>
                        <td>
                            <span class="badge badge-light" v-if="mod.file_url==undefined"> - </span>
                            <a class="badge badge-primary" v-else :href="mod.file_url" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16"> <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/> <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/> </svg>
                                Ver
                            </a>
                        </td>
                        <td>
                            <span class="badge badge-light" v-if="mod.images==undefined"> - </span>
                            <span class="badge badge-primary" v-else>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>
                                {{mod.images.length}}
                            </span>
                        </td>
                    <td class="text-right">
                        <button class="btn btn btn-primary ml-2" @click.prevent="move(mod,-1)" :disabled="i==0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-circle" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/> </svg>
                            ARRIBA
                        </button>
                        <button class="btn btn btn-primary ml-2" @click.prevent="move(mod,1)" :disabled="i==orderedModules.length-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-circle" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"/> </svg>                            
                            ABAJO
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="text-right mt-3 pr-3">
            <button class="btn btn-success" @click.prevent="submit">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16"> <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/> <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/> </svg>
                Guardar orden
            </button>
        </div>
    </div>
</template>

<script>
// import { tableConfig } from "@/env";
// import $ from 'jquery'
// import Swal from "sweetalert2";

import firebase from "firebase/app";
import "firebase/database";

export default {
    name:'OrderModules',
    props: {modules:Object,course_id:String},
    data() {
        return {
            orderedModules:null,
        }
    },
    methods: {
        move(item,direction){
            let newIndex = item.order + (direction);

            let oldIndex = this.orderedModules[newIndex].order + (direction)*(-1);

            console.log('newIndex',newIndex,'oldIndex',oldIndex);

            let aux = this.orderedModules[newIndex];

            item.order = newIndex;
            this.orderedModules[newIndex].order = oldIndex;

            this.orderedModules[newIndex] = item;
            this.orderedModules[oldIndex] = aux;
        },
        submit(event){
            // Se actualiza la vista para mostrar es estado de carga
            event.target.innerHTML = `
                <div class="spinner-border" style="width:12px;height:12px" role="status">
                    <span class="sr-only">Loading...</span>
                </div> Guardando
            `
            event.target.disabled = true;
            for (let input of document.querySelectorAll('btn')) {
                input.disabled = true;
            }



            let updatedModules = {};
            Object.keys(this.orderedModules).forEach((m,i)=>{
                updatedModules[this.orderedModules[m].fireId] = this.orderedModules[m];
                delete updatedModules[this.orderedModules[m].fireId].fireId;

                this.orderedModules[m].order = i;
            });

            firebase.database().ref(`courses/${this.course_id}/modules`).update(updatedModules).then(()=>{
                console.log('%c✓ Ordered modules updated','color:#0f0');
                this.$emit('end',true);
            }).catch((e)=>{console.error(e);alert('Hubo un error al guardar los modulos, actualizaremos la página.');document.location.reload()});

            
            
            
            // console.log(JSON.parse(JSON.stringify(updatedModules)));
        }
    },
    mounted(){
        let ordered = [];
        let withOrder = [];

        Object.keys(this.modules).forEach((fId)=>{
            if(this.modules[fId].order != undefined) {
                withOrder.push({...this.modules[fId],...{fireId:fId}});
            }else {
                ordered.push({...this.modules[fId],...{fireId:fId}});
            }
        });

        withOrder.forEach((e)=>{
            // console.log('ord',e.order);
            // console.log('obj',ordered[e.order]);

            let messy;
            if(ordered[e.order]!=undefined) messy = JSON.parse(JSON.stringify(ordered[e.order]));
            
            ordered[e.order] = e;
            if(messy!==undefined) ordered.push(messy);
        });

        ordered.forEach((e,i)=>{
            // if(e.order===undefined) 
            e.order = i;
        });

        this.orderedModules = ordered.filter((e)=>e!=null&&e!=undefined);

        let aux = {};
        Object.keys(this.orderedModules).forEach((o,i)=>{
            this.orderedModules[o].order = i;
            aux[i]=this.orderedModules[o];
        });

        this.orderedModules = aux;

        console.log(this.orderedModules);

        console.log('%c✓ Ordered modules','color:#0f0');
    }
}
</script>

<style>

</style>