<template>
    <div>
        <h4 class="mb-4">Lista de cursos</h4>
        <div class="table-responsive">
            <table id="datatable" class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Nombre</th>
                        <th>Creación</th>
                        <th>Modulos</th>
                        <th>Estado</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(course, i) in courses" :key="i" >
                        <td>
                            <img :src="course.cover" v-if="course.cover!=undefined" class="list-image">
                            <img src="/assets/images/no-image-graduation.png" v-else class="list-image">
                        </td>
                        <td>{{course.title}}</td>
                        <td>{{dateFormateR(course.created_at)}}</td>
                        <td><div class="badge badge-light">{{(course.modules!=undefined)?Object.keys(course.modules).length:0}}</div></td>
                        <td>
                            <toggle-button 
                                :estadoInicial="course.active" 
                                @estado="handleActiveState(i,$event)" 
                                :estados="['Activo','Inactivo']"
                            ></toggle-button>
                        </td>
                        <td>
                            <div class="btn btn btn-primary ml-2" @click.prevent="$emit('update',{...course,...{fireId:i}})">Modificar curso</div>
                            <div class="btn btn btn-primary ml-2" @click.prevent="$emit('modules',{...course,...{fireId:i}})">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                                Gestionar modulos
                            </div>
                            <div class="btn btn btn-danger ml-2" @click.prevent="handleDelete(i)">Eliminar</div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { tableConfig } from "@/env";
import {dateFormat} from '@/utils';
import $ from 'jquery'
import ToggleButton from '../ToggleButton.vue';
import firebase from "firebase/app";
import "firebase/database";
import Swal from "sweetalert2";

export default {
    components: { ToggleButton },
    name:'ListCourses',
    props: {courses:Object},
    methods: {
        handleActiveState(fireId,state){
            firebase.database().ref(`/courses/${fireId}`).update({active:state}).then(()=>{
                console.info('%c✓ course updated','color:#0f0');
            }).catch((error)=>console.error(error));
        },
        dateFormateR(date){return dateFormat(date)},
        handleDelete(courseId){
            Swal.fire({
                title: '¿Vas a eliminar el curso?',
                text: "Cuidado! Esta acción no se puede deshacer",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar!'
            }).then((result) => {
                if (result.isConfirmed) {
                    firebase.database().ref(`courses/${courseId}`).remove().then(()=>{
                        Swal.fire(
                            'Todo listo!',
                            'El curso fué eliminado.',
                            'success'
                        )

                        // this.updateAndList();
                        this.$emit('update-list');
                    })
                }
            })
        },
    },
    mounted(){
        $('#datatable').dataTable().fnDestroy();
        $('#datatable').DataTable(tableConfig);
    }
}
</script>

<style>

</style>