<template>
   <button class="btn btn-md btn-success p-1 boton" v-if="state" @click="changeState()">
        <svg class="mr-1 feather feather-toggle-right" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect><circle cx="16" cy="12" r="3"></circle></svg>
        {{estados[0]}}
    </button>
    <button class="btn btn-md btn-light p-1 boton" v-else @click="changeState()">
        <svg class="mr-1 feather feather-toggle-right" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="1" y="5" width="22" height="14" rx="7" ry="7"></rect><circle cx="8" cy="12" r="3"></circle></svg>
        {{estados[1]}}
    </button>
</template>

<script>
export default {
    name: 'ToggleButton',
    props: {estadoInicial:Boolean,estados:Array},
    data(){
        return {
            state: false
        }
    },
    methods:{
        changeState(){
            this.state = !this.state
            this.$emit('estado', this.state)
        }
    },
    mounted(){
        this.state = (this.estadoInicial != undefined) ? this.estadoInicial : false
    }
}
</script>

<style scoped>
    .boton {
        width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: .6rem !important;
    padding-left: .6rem !important;
    }

    svg {
        margin-bottom: 2px;
    }
</style>