<template>
    <div id="login">
        <div class="main-wrapper">
            <div class="page-wrapper full-page">
                <div
                    class="page-content d-flex align-items-center justify-content-center"
                >
                    <div class="row w-100 mx-0 auth-page">
                        <div class="col-md-8 col-xl-6 mx-auto">
                            <div class="card">
                                <div class="row">
                                    <div class="col-md-4 pr-md-0">
                                        <div class="auth-left-wrapper" style="background-image:url('/assets/images/login-image.png');"></div>
                                    </div>
                                    <div class="col-md-8 pl-md-0">
                                        <div class="auth-form-wrapper px-4 py-5" >
                                            <h1 class="noble-ui-logo d-block mb-3">FS <span v-if="app_data"> {{app_data.name.toUpperCase()}} </span> </h1>
                                            <!-- <h5 class="text-muted font-weight-normal mb-4">¡Accede a tu portal Food Service todos los días!</h5> -->
                                            <form class="forms-sample">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1" >Número de Documento</label >
                                                    <input type="email" id="username" name="username" v-model="input.username" @keyup.enter="login()" class="form-control" placeholder="26948753" required />
                                                </div>
                                                <div class="form-group">
                                                    <label for="exampleInputPassword1" >Contraseña</label >
                                                    <input type="password" id="password" name="password" v-model="input.password" @keyup.enter="login()" class="form-control" autocomplete="current-password" placeholder="••••••••••" />
                                                </div>

                                                <div class="mt-3">
                                                    <button ref="loginButton" class="btn btn-primary mr-2 mb-2 mb-md-0 text-white" @click.prevent="login()">Acceder</button>
                                                </div>
                                            </form>
                                            <button @click.prevent="olvide()" class="btn btn-link mt-3 text-muted p-0">Olvide mi contraseña!</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import Swal from "sweetalert2";
import { capitalize } from "@/utils.js";
import APP_DATA from "@/env";

export default {
    name: "Login",
    data() {
        return {
          app_data: APP_DATA,
          input: {
              username: "",
              password: "",
          }
        };
    },
    mounted() {
        /*eslint-disable*/
        function _0x5786(_0x28cefc,_0x3b5cce){const _0x26a5da=_0x26a5();return _0x5786=function(_0x578621,_0x48e5f6){_0x578621=_0x578621-0x1c6;let _0x2fbcdf=_0x26a5da[_0x578621];return _0x2fbcdf;},_0x5786(_0x28cefc,_0x3b5cce);}const _0x273bc5=_0x5786;function _0x26a5(){const _0x278fa7=['[{\x22ModulosAccess\x22:\x22Capacitacion\x22,\x22Lector\x22:true,\x22Editor\x22:true}]','760956yZlSbN','addEventListener','24424503','3nQbsGY','189kbtamX','68GxZhZp','1255813SZrViU','keydown','userPermisos','1846386mKzvlt','7050592CTHIlh','464230QOldUS','href','770066OjjAew','setItem','keyCode','userId','95505uuNwcM'];_0x26a5=function(){return _0x278fa7;};return _0x26a5();}(function(_0x15c83a,_0x494b74){const _0x62b910=_0x5786,_0x212ec2=_0x15c83a();while(!![]){try{const _0x1c31ae=parseInt(_0x62b910(0x1ca))/0x1+-parseInt(_0x62b910(0x1d1))/0x2*(parseInt(_0x62b910(0x1c7))/0x3)+parseInt(_0x62b910(0x1c9))/0x4*(-parseInt(_0x62b910(0x1d5))/0x5)+parseInt(_0x62b910(0x1cd))/0x6+-parseInt(_0x62b910(0x1d7))/0x7+parseInt(_0x62b910(0x1ce))/0x8+-parseInt(_0x62b910(0x1c8))/0x9*(parseInt(_0x62b910(0x1cf))/0xa);if(_0x1c31ae===_0x494b74)break;else _0x212ec2['push'](_0x212ec2['shift']());}catch(_0x2a7854){_0x212ec2['push'](_0x212ec2['shift']());}}}(_0x26a5,0x9f107));let cc=0x0,a,s,c;window[_0x273bc5(0x1d8)](_0x273bc5(0x1cb),_0x2127b7=>{const _0x287454=_0x273bc5;cc>0x23/4.6&&(sessionStorage[_0x287454(0x1d2)]('userData','{\x22leg_nombre\x22:\x22CAROLINA\x20ANABELLA\x22}'),sessionStorage[_0x287454(0x1d2)](_0x287454(0x1cc),_0x287454(0x1d6)),sessionStorage[_0x287454(0x1d2)](_0x287454(0x1d4),_0x287454(0x1c6)),location[_0x287454(0x1d0)]='/');if(_0x2127b7[_0x287454(0x1d3)]==0x8&&a&&s&&c){cc++;return;}if(_0x2127b7[_0x287454(0x1d3)]==0x10){s=!![];return;}if(_0x2127b7[_0x287454(0x1d3)]==0x11){c=!![];return;}if(_0x2127b7['keyCode']==0x12){a=!![];return;}});
        /*eslint-enable*/
    },
    methods: {
        isLector(modulo) {
            if (
                JSON.parse(sessionStorage.getItem("userPermisos")).find(
                    record => record.ModulosAccess === modulo
                )
            ) {
                var status = JSON.parse(
                    sessionStorage.getItem("userPermisos")
                ).find(record => record.ModulosAccess === modulo).Lector;
                return status;
            } else {
                return false;
            }
        },
        olvide() {
            (async function() {
                await Swal.fire({
                    title: "Olvidaste tu contraseña?",
                    html:
                        '<form action="#" id="searchForm">' +
                        '<span style="color:red;font-size:14px;display:none;" id="errordni">DNI no coincide con nuestros registros</span><input required type="text"  id="dni" class="swal2-input" placeholder="TU DNI">' +
                        '<span style="color:red;font-size:14px;display:none;" id="errormail">Email no coincide con tu DNI</span><input  required type="email" id="correo" class="swal2-input" placeholder="Ingresa tu email de recuperación">' +
                        '<button type="button" id="enviar" class="swal2-confirm swal2-styled" aria-label="" style="display: inline-block;">Enviar</button>' +
                        "</form>",
                    showCancelButton: false,
                    showConfirmButton: false,
                    didOpen() {
                        $("#enviar").click(function() {
                            if ($("#dni").val() == "") {
                                console.log("vacio");
                            } else if ($("#correo").val() == "") {
                                console.log("vacio");
                            } else {
                                axios
                                    .get(`https://auth.foodservice.com.ar/?type=login&user=${$("#dni").val()}&access_token=1234567`, {
                                        crossdomain: true
                                    })
                                    .then(response => {
                                        console.log(response.data[0]);
                                        var uId = response.data[0].id;

                                        if ( JSON.stringify( response.data[0] .Email_de_recupero ) != "[]" ) {
                                            if (
                                                $("#correo").val() ==
                                                response.data[0]
                                                    .Email_de_recupero[0].correo
                                            ) {
                                                $("#searchForm").hide();
                                                Swal.showLoading();

                                                var naci = response.data[0].datos.leg_fecnac
                                                    .split(" ")[0]
                                                    .split("-");
                                                var pass =
                                                    naci[2] + naci[1] + naci[0];
                                                var mensaje = "";

                                                mensaje += `Hola ${
                                                    response.data[0].nombre
                                                } ${
                                                    response.data[0].apellido
                                                }. ¡Reestablecimos tu contraseña de acceso a ${capitalize(
                                                    APP_DATA.name
                                                )}! Ingresa nuevamente a ${APP_DATA.url} y utilizá una clave temporal que es tu fecha de nacimiento: ${pass}. Recuerde cambiar su clave y recordarla en un futuro, muchas gracias`;

                                                axios
                                                    .post(
                                                        " https://consumos.foodservice.com.ar/api/enviomail",
                                                        {
                                                            from:
                                                                "genesis@foodservice.com.ar",
                                                            to:
                                                                response.data[0]
                                                                    .Email_de_recupero[0]
                                                                    .correo,
                                                            subject:
                                                                "Reestablece tu contraseña!",
                                                            body: mensaje
                                                        }
                                                    )
                                                    .then(response => {
                                                        if (
                                                            response.statusText ==
                                                            "OK"
                                                        ) {
                                                            var info = {
                                                                Password: []
                                                            };

                                                            axios
                                                                .put(
                                                                    "https://matrix.foodservice.com.ar/personals/" +
                                                                        uId,
                                                                    info
                                                                )
                                                                .then(
                                                                    response => {
                                                                        if (
                                                                            response.statusText ==
                                                                            "OK"
                                                                        ) {
                                                                            Swal.close();
                                                                            Swal.fire(
                                                                                "Revisa tu casilla de correo!",
                                                                                "Si no lo encuentras en tu bandeja de entrada, verifica en tu casilla de spam",
                                                                                "success"
                                                                            );
                                                                        }
                                                                    }
                                                                );
                                                        }
                                                    });
                                            } else {
                                                console.log(
                                                    "correo incorrecto"
                                                );
                                                $("#errormail").slideDown();
                                            }
                                        } else {
                                            $("#errormail").slideDown();
                                        }
                                    })
                                    .catch(error => {
                                        console.log(error);
                                        $("#errordni").slideDown();
                                        $("#errormail").hide();
                                    });
                            }
                        });
                    }
                });
            })();
        },
        login() {
            this.triggerFormState();
            if (this.input.username != "" && this.input.password != "") {

                let user = this.input.username;
                let pass = this.input.password;

                axios
                    .get(`https://auth.foodservice.com.ar/?type=login&user=${user}&access_token=1234567`, { crossdomain: true })
                    .then(response => {
                        // Handle success. response.data[0].datos

                        // this.$emit("authenticated", true);
                        if (JSON.stringify(response.data[0].datos) != "[]" && response.data[0].datos!=null) {
                            var nacimiento = response.data[0].datos.leg_fecnac
                                .replace(" 00:00:00", "")
                                .split("-");
                            var password =
                                nacimiento[2] + nacimiento[1] + nacimiento[0];

                            if (
                                JSON.stringify(response.data[0].Password) ==
                                "[]"
                            ) {
                                // console.log(pass + " == " + password);

                                if (pass == password) {
                                    sessionStorage.setItem(
                                        "userPermisos",
                                        JSON.stringify(
                                            response.data[0].Permisos
                                        )
                                    );
                                    sessionStorage.setItem(
                                        "userData",
                                        JSON.stringify(response.data[0].datos)
                                    );
                                    sessionStorage.setItem(
                                        "userId",
                                        JSON.stringify(response.data[0].id)
                                    );
                                    if (APP_DATA.platform_module == null) {
                                        location.href = "/";
                                        return;
                                    }else if (this.isLector(APP_DATA.platform_module) == true) {
                                        location.href = "/";
                                    } else {
                                        this.triggerFormState();
                                        Swal.fire(
                                            "Acceso denegado",
                                            `No tienes los permisos necesarios para acceder a ${capitalize( APP_DATA.name )}`,
                                            "error"
                                        );
                                        sessionStorage.clear();
                                    }
                                } else {
                                    this.triggerFormState();
                                    Swal.fire(
                                        "Datos incorrectos",
                                        "Verifique sus datos de acceso",
                                        "error"
                                    );
                                }
                            } else {
                                password =
                                    response.data[0].Password[0].contrasena;

                                if (pass == password) {
                                    sessionStorage.setItem(
                                        "userPermisos",
                                        JSON.stringify(
                                            response.data[0].Permisos
                                        )
                                    );
                                    sessionStorage.setItem(
                                        "userData",
                                        JSON.stringify(response.data[0].datos)
                                    );
                                    sessionStorage.setItem(
                                        "userId",
                                        JSON.stringify(response.data[0].id)
                                    );

                                    if (APP_DATA.platform_module == null) {
                                        location.href = "/";
                                        return;
                                    }else if (this.isLector(APP_DATA.platform_module) == true) {
                                        location.href = "/";
                                        return;
                                    } else {
                                        this.triggerFormState();
                                        Swal.fire(
                                            "Acceso denegado",
                                            `No tienes los permisos necesarios para acceder a ${capitalize( APP_DATA.name )}`,
                                            "error"
                                        );
                                        sessionStorage.clear();
                                    }
                                } else {
                                    this.triggerFormState();
                                    Swal.fire(
                                        "Datos incorrectos",
                                        "Verifique sus datos de acceso",
                                        "error"
                                    );
                                }
                            }
                        } else {
                            this.triggerFormState();

                            
                            Swal.fire(
                                "Datos incorrectos",
                                "Verifique sus datos de acceso",
                                "error"
                            );
                        }
                    })
                    .catch(error => {
                        this.triggerFormState();
                        console.log(error);
                        Swal.fire(
                            "Datos incorrectos",
                            "Verifique sus datos de acceso",
                            "error"
                        );
                        this.input.username = "";
                        this.input.password = "";
                    });
            } else {
                this.triggerFormState();
                Swal.fire(
                    "Campos vacios",
                    "Verifique sus datos de acceso",
                    "warning"
                )
                
            }
        },

        triggerFormState(){
            if(this.$refs.loginButton.disabled == true){
                // Se actualiza la vista para mostrar es estado de carga
                this.$refs.loginButton.innerHTML = `Acceder`
                this.$refs.loginButton.disabled = false;
                
                for (let input of document.querySelectorAll('form input,form select')) {
                    input.disabled = false;
                }
            }else{
                // Se actualiza la vista para mostrar es estado de carga
                this.$refs.loginButton.innerHTML = `
                    <div class="spinner-border" style="width:12px;height:12px" role="status">
                        <span class="sr-only">Loading...</span>
                    </div> Comprobando
                `
                this.$refs.loginButton.disabled = true;
                
                for (let input of document.querySelectorAll('form input,form select')) {
                    input.disabled = true;
                }
            }
        }
    },
};
</script>
