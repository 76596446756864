<template>
    <div>
        <div class="d-flex-center-between mb-4">
            <h4 class="">Lista de material</h4>
        </div>
        <div class="table-responsive" v-if="!trigger.ordering">
            <table id="datatable" class="table">
                <thead>
                    <tr>
                        <th hidden></th>
                        <th></th>
                        <th>Titulo</th>
                        <th>Categoría</th>
                        <th>Texto</th>
                        <th>Video</th>
                        <th>PDF</th>
                        <th>Galeria</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(resource, i) in resources" :key="i">
                        <td hidden>{{resource.order}}</td>
                        <td><img :src="resource.cover" class="list-image" alt=""></td>
                        <td>{{resource.title}}</td>
                        <td>
                            <div class="badge badge-light">{{(resource.category != undefined) ? capitalizeR(resource.category) : 'Sin asignar'}}</div>
                        </td>
                        <td>
                            <span class="badge badge-light" v-if="!isset(resource.text)"> - </span>
                            <a class="badge badge-primary text-white" v-else @click.prevent="handleSelectOption(resource)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-justify-left" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/> </svg>
                                Ver
                            </a>
                        </td>
                        <td>
                            <span class="badge badge-light" v-if="!isset(resource.video_url)"> - </span>
                            <a class="badge badge-primary" v-else :href="resource.video_url" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16"> <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/> <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/> </svg>
                                Ver
                            </a>
                        </td>
                        <td>
                            <span class="badge badge-light" v-if="!isset(resource.files)"> - </span>
                            <a class="badge badge-primary text-white" v-else @click.prevent="handleSelectOption(resource)" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-file-earmark-text" viewBox="0 0 16 16"> <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/> <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/> </svg>
                                {{resource.files.length}}
                            </a>
                        </td>
                        <td>
                            <span class="badge badge-light" v-if="!isset(resource.images)"> - </span>
                            <span class="badge badge-primary" v-else>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-image"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><circle cx="8.5" cy="8.5" r="1.5"></circle><polyline points="21 15 16 10 5 21"></polyline></svg>
                                {{resource.images.length}}
                            </span>
                        </td>
                       
                        <td>
                            <div class="btn btn btn-primary ml-2" @click.prevent="$emit('update',{...resource,...{fireId:i}})">
                                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M12 20h9"></path><path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path></svg>
                                Modificar
                            </div>
                            <div class="btn btn btn-primary ml-2" @click.prevent="handleSelectOption(resource)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                                Ver
                            </div>
                            <div class="btn btn btn-danger ml-2" @click.prevent="handleDelete(i)">
                                <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                                Eliminar
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <modal-resource v-if="selected" @close="handleCloseModal()">
        <template v-slot:header>
            <div class="row align-items-center">
                <div class="col-4">
                    <img :src="selected.cover" style="height:140px;width:100%;object-fit:cover;border-radius:6px;">
                </div>
                <div class="col text-left">
                    <h4>{{selected.title}}</h4>
                    <p>{{selected.description}}</p>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col">
                    <div class="accordion" id="accordionExample">
                        <div class="card" v-if="selected.text">
                            <div class="card-header" id="headingOne">
                            <h2 class="mb-0">
                                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Contenido en texto
                                </button>
                            </h2>
                            </div>

                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <pre class="text-left" style="height:315px; overflow-y: scroll;">{{selected.text}}</pre>
                                </div>
                            </div>
                        </div>
                        <div class="card" v-if="selected.images">
                            <div class="card-header" id="headingOne">
                            <h2 class="mb-0">
                                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Contenido en imágenes
                                </button>
                            </h2>
                            </div>

                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div class="card-body">
                                    <div class="images-modal">
                                        <img :src="img" v-for="(img,i) in selected.images" :key="i">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" v-if="selected.video_url">
                            <div class="card-header" id="headingTwo">
                            <h2 class="mb-0">
                                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Contenido en video
                                </button>
                            </h2>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                            <div class="card-body">
                                <!-- <iframe width="560" height="315" :src="`https://www.youtube.com/embed/${getYoutubeID(selected.video_url)}`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                                <iframe :src="getDrivePreviewUrl(selected.video_url)" width="560" height="315"></iframe>    
                            </div>
                            </div>
                        </div>
                        <div class="card" v-if="selected.files">
                            <div class="card-header" id="headingThree">
                                <h2 class="mb-0">
                                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                        Contenido en PDF
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                <div class="card-body" id="card-content-pdf">
                                    <template v-if="isArray(selected.files) && selected.files.length>1">
                                        <div class="card" v-for="file,f in selected.files" :key="f">


                                            <div class="accordion" id="pdfaccordion">
                                                <div class="card">
                                                    <div class="card-header" :id="'pdf'+(f+1)">
                                                        <h2 class="mb-0">
                                                            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" :data-target="'#pdf-collapse'+(f+1)" aria-expanded="true" :aria-controls="'pdf-collapse'+(f+1)">
                                                                {{convertFileName(file)}}
                                                            </button>
                                                        </h2>
                                                    </div>

                                                    <div :id="'pdf-collapse'+(f+1)" class="collapse" :aria-labelledby="'pdf'+(f+1)" data-parent="#pdfaccordion">
                                                        <div class="card-body">
                                                            <object 
                                                            :data="file" 
                                                            type='application/pdf' 
                                                            width='100%' 
                                                            height='300px'></object>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>








                                            <!-- <div class="accordion" id="accordionExample2">
                                                <div class="card-header" :id="'pdf'+(f+1)">
                                                    <h2 class="mb-0">
                                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" :data-target="'#pdf'+(f+1)" aria-expanded="false" :aria-controls="'pdf'+(f+1)">
                                                            {{convertFileName(file)}}
                                                        </button>
                                                    </h2>
                                                </div>
                                                <div :id="'pdf'+(f+1)" class="collapse" :aria-labelledby="'pdf'+(f+1)" data-parent="#accordionExample2">
                                                    <div class="card-body">
                                                        <object 
                                                        :data="file" 
                                                        type='application/pdf' 
                                                        width='100%' 
                                                        height='300px'></object>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>


                                        <!-- <div class="tab">
                                            <button class="tablinks" @click.prevent="openCity($event, 'pdf'+(f+1))" v-for="file,f in selected.files" :key="f">{{convertFileName(file)}}</button>
                                        </div>
    
                                        <div :id="'pdf'+(f+1)" class="tabcontent" v-for="url,f in selected.files" :key="f">
                                            <object 
                                                :data="url" 
                                                type='application/pdf' 
                                                width='100%' 
                                                height='300px'></object>
                                        </div> -->  
                                    </template>

                                     <object 
                                            v-else
                                            :data="selected.files[0]" 
                                            type='application/pdf' 
                                            width='100%' 
                                            height='300px'></object>

                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </template>
    </modal-resource>
</template>

<script>
import { tableConfig } from "@/env";
import { capitalize } from "../../utils";
import $ from 'jquery'
import Swal from "sweetalert2";

import firebase from "firebase/app";
import "firebase/database";

import ModalResource from "./ModalResource";

export default {
    name:'ListResources',
    props: {resources:Object},
    components:{ModalResource,},
    data() {
        return {
            trigger:{ordering:false},
            selected:false,
        }
    },
    methods: {
        handleSelect(item){
            this.selected = item;
        },
        handleDelete(resourceId){
            Swal.fire({
                title: '¿Vas a eliminar el recurso?',
                text: "Cuidado! Esta acción no se puede deshacer",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar!'
            }).then((result) => {
                if (result.isConfirmed) {
                    firebase.database().ref(`resources/${resourceId}/`).remove().then(()=>{
                        Swal.fire(
                            'Todo listo!',
                            'El material fué eliminado.',
                            'success'
                        )
                        // this.updateAndList();
                        this.$emit('removed',true);
                        
                    
                    })
                }
            })
        },
        handleSelectOption(item){this.selected = item},
        handleCloseModal(){ this.selected = null },
        getYoutubeID(url){
            const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
            const match = url.match(regExp);

            return (match && match[2].length === 11)
            ? match[2]
            : null;
        },
        getDrivePreviewUrl(url){return url.replace('view','preview')},
        isset(s){return s!=undefined && s!=null && s!='' && s.length},
        openCity(evt, cityName) {
            // Declare all variables
            var i, tabcontent, tablinks;

            // Get all elements with class="tabcontent" and hide them
            tabcontent = document.getElementsByClassName("tabcontent");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }

            // Get all elements with class="tablinks" and remove the class "active"
            tablinks = document.getElementsByClassName("tablinks");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }

            // Show the current tab, and add an "active" class to the button that opened the tab
            document.getElementById(cityName).style.display = "block";
            evt.currentTarget.className += " active";
        },
        isArray(value){return Array.isArray(value)},
        capitalizeR(s){return capitalize(s)},
        convertFileName(file){
            let arrayName = file.split('files%2F')[1].split('?alt=media')[0].split('_');
            arrayName.shift();
            return arrayName.join(' ').replace('pdf','');
        }
    
    },
    mounted(){
        $('#datatable').dataTable().fnDestroy();
        $('#datatable').DataTable(tableConfig);
    }
}
</script>

<style scoped>
#card-content-pdf {
    height:54vh;
    overflow-y: scroll;
}
.images-modal {
    display: flex;
    flex-wrap: wrap;
}

.images-modal img{
    width:180px;
    height:180px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 6px;
}

pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

 /* Style the tab */
.tab {
    overflow: hidden;
    border: 1px solid #eee;
    background-color: #fff;
}

/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  color:#007bff;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
    background-color: #007bff;
    color: #fff;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
} 
</style>