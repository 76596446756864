const APP_DATA = {
    name:'capacitacion',
    env:'production',
    platform_module: 'Capacitacion', // null (genera un bypass en login para cualquier usuario en nómina)
    db_url:{
        production: 'https://capacitacion-fs.firebaseio.com/',
        develop: 'https://fs-capacitacion-default-rtdb.firebaseio.com'
    },
    db_config:{
        production: {
            apiKey: "AIzaSyBLyOhPH9YaePUSi5fIMjaavlrQVJ2-UnY",
            authDomain: "cantina-89c6a.firebaseapp.com",
            databaseURL: "capacitacion-fs.firebaseio.com",
            projectId: "cantina-89c6a",
            storageBucket: "cantina-89c6a.appspot.com",
            messagingSenderId: "353062217145",
            appId: "1:353062217145:web:8849a49a1ec52077"
        },
        develop: {
            apiKey: "AIzaSyBDn9xcLdbuKRCOPGCkoWSpUlKM0QdUTPc",
            authDomain: "fs-capacitacion.firebaseapp.com",
            databaseURL: "https://fs-capacitacion-default-rtdb.firebaseio.com",
            projectId: "fs-capacitacion",
            storageBucket: "fs-capacitacion.appspot.com",
            messagingSenderId: "28097268661",
            appId: "1:28097268661:web:94e4d3f2510748b73867fc"
        }
    },
    url: 'https://capacitacion.foodservice.com.ar',
    description: 'Esta aplicación te permite generar capacitaciones y consultar las metricas relacionadas.',
}

export default APP_DATA;
  
export var tableConfig = {
    "language": {
        "decimal": ",",
        "thousands": ".",
        "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
        "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
        "infoPostFix": "",
        "infoFiltered": "(filtrado de un total de _MAX_ registros)",
        "loadingRecords": "Cargando...",
        "lengthMenu": "Mostrar _MENU_ registros",
        "paginate": {
            "first": "Primero",
            "last": "Último",
            "next": "Siguiente",
            "previous": "Anterior"
        },
        "processing": "Procesando...",
        "search": "Buscar:",
        "searchPlaceholder": "Término de búsqueda",
        "zeroRecords": "No se encontraron resultados",
        "emptyTable": "Ningún dato disponible en esta tabla",
        "aria": {
            "sortAscending":  ": Activar para ordenar la columna de manera ascendente",
            "sortDescending": ": Activar para ordenar la columna de manera descendente"
        },
    },
    "scrollX": false, 
    "search": {regex:true},
    dom: 'Bfrtip',
    responsive: false,
    nowrap: false,
    buttons: [
        'copy',
        'excel',
        'csv',
        'pdf'
    ]
};
  