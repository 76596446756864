<template>
  <div class="row">
      <div class="col">
        <h4 class="mb-4">Modificar curso</h4>
        <form>
            <div class="form-row mb-4">
                <div class="col-12 col-md-4 mb-3">
                    <label class="form-label">Título</label>
                    <input type="text" class="form-control" v-model="course.title">
                    <div class="text-danger mt-1" v-if="error.title">{{error.title}}</div>
                </div>
                 <div class="col-12 col-md-2 mb-3">
                    <label class="form-label">Activo</label>
                    <toggle-button
                     :estadoInicial="course.active" 
                     @estado="course.active = $event" 
                     :estados="['Activo','Inactivo']"
                    ></toggle-button>
                    <!-- <div class="text-danger mt-1" v-if="error.title">{{error.title}}</div> -->
                </div>
                <div class="col-12 col-md-6 mb-3">
                    <label class="form-label">Descripcion</label>
                    <input type="text" class="form-control" v-model="course.description">
                    <div class="text-danger mt-1" v-if="error.description">{{error.description}}</div>
                </div>
                <div class="col-12  mb-3">
                    <input type="file" accept="image/*" id="input-file" @change="handleImagePicked($event,question)"/>

                    <div class="form-label">Imagen</div>
                    <div class="d-flex-center-between image-box">
                        <button class="btn btn-primary" @click.prevent="openFileDialog()">Seleccionar</button>
                        <img :src="course.cover" alt="" v-if="course.cover">
                        <div class="bg-light no-image" v-else @click.prevent="openFileDialog()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-image" viewBox="0 0 16 16">
                                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/>
                            </svg>
                        </div>
                        <button class="btn btn-danger rounded-circle p-2" @click.prevent="removeImage(question)" v-if="course.cover">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16"> <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/> </svg>
                        </button>
                    </div>
                </div>
                <div class="col-12 mb-3">
                    <label class="form-label">Objetivos del curso</label>
                    <div class="d-flex mb-2" aria-disabled="true">
                        <input type="text" class="form-control" placeholder="Nuevo objetivo" v-model="newGoal"> 
                        <button class="btn btn-primary ml-3" style="min-width: fit-content;" @click.prevent="addGoal()">Agregar objetivo</button>
                    </div>
                    <ul class="list-group border-dark">
                        <li class="list-group-item border-dark d-flex-center-between" v-for="(goal,g) in course.goals" :key="g">
                            <span>
                                {{goal}}
                            </span> 
                            <button class="btn btn-danger p-1" @click.prevent="removeGoal(g)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-circle"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                            </button>
                        </li>
                    </ul>
                    <div class="text-danger mt-1" v-if="error.goals">{{error.goals}}</div>
                </div>
            </div>

            <div class="form-row">
                <div class="col text-right">
                    <div class="btn btn-md btn-success" @click.prevent="submit">Modificar curso</div>
                </div>
            </div>
        </form>
      </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
import {isEmpty,putBase64} from '@/utils';
import ToggleButton from '@/components/ToggleButton'
export default {
    name:'CreateCourse',
    props:{courses:Object,selected:Object},
    components:{ToggleButton},
    data() {
        return {
            course:{
                title:null,
                description:null,
                goals:[],
                cover:null,
            },
            newGoal:null,
            validProps:['title','description'],
            error:{
                title:null,
                description:null,
                // goals:null
            },
        }
    }, 
    mounted(){
        this.course = JSON.parse(JSON.stringify(this.selected));
        if(this.selected.goals == undefined) this.course.goals = [];
        delete this.course.fireId;
    },
    methods:{
        removeImage(){ this.course.cover = null },
        openFileDialog() { document.getElementById('input-file').click() },
        handleImagePicked(e){
            let file = e.target.files[0];

            let reader = new FileReader()
            reader.onload = (event) => {
                let img = new Image();
                img.src = event.target.result;
    
                img.onload = () => {
                    let canvas = document.createElement('canvas')
                    const MAX_WIDTH = 900
                    const MAX_HEIGHT = 900
                    let width = img.width
                    let height = img.height
    
                    if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width
                        width = MAX_WIDTH
                    }
                    } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height
                        height = MAX_HEIGHT
                    }
                    }
                    canvas.width = width
                    canvas.height = height
                    let ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0, width, height)
                    let data = canvas.toDataURL();


                    this.course.cover = data;
                    // let index = Object.keys(this.files).length;
                    // setProp(this.files,index,data);

                    this.$forceUpdate();
                    putBase64(data).then((url)=>{
                        if (this.course.cover) {
                            this.course.cover = url;
                        }
                    });
                }
            }
            reader.readAsDataURL(file)
        },
        addGoal(){
            if(isEmpty(this.newGoal)) return null;
            this.course.goals.push(this.newGoal.trim());
            this.newGoal = null;
        },
        removeGoal(i){
            this.course.goals.splice(i,1);
        },
        submit(e){
           
             e.target.innerHTML = `
                <div class="spinner-border" style="width:12px;height:12px" role="status">
                    <span class="sr-only">Loading...</span>
                </div> Guardando
            `
            e.target.disabled = true;

            for (let input of document.querySelectorAll('form input,form select,form textarea,form button')) {
                input.disabled = true;
            }

            if(!this.isValid()){
                e.target.innerHTML = 'Modificar curso';
                e.target.disabled = true;
                for (let input of document.querySelectorAll('form input,form select,form textarea,form button')) {
                    input.disabled = false;
                }

                return; // exit cuz the form is invalid
            }

            /**
             * The form is valid
             */


            firebase.database().ref(`/courses/${this.selected.fireId}`).update(this.course).then(()=>{
                console.info('%c✓ updated course','color:#0f0');
                console.log(this.course)
                this.$emit('end',true);
            })
        },
        isValid(){
            let valid = true;
            this.validProps.forEach((prop) => {
                this.error[prop] = '';
                if (isEmpty(this.course[prop])) {
                    valid = false;
                    this.error[prop] = 'No puede estar vacio';
                }
            });
            return valid
        },
    }     
}
</script>

<style>


input[type="file"] {
  display: none;
}

.image-box img {
    height: 164px;
    display: block;
    width: 79%;
    object-fit: cover;
    border-radius: 4px;
}

.image-box .btn-primary {
    width: 20%;
    height: 164px;
}
.image-box .btn-danger {
    position: absolute;
    right: 32px;
}

.image-box .no-image {
    height: 164px;
    width: 79%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-image svg {
    width: 50px;
    height: 50px;
    opacity: .2;
}
</style>