<template>
    <div class="row">
        <div class="col-12">
            <div class="d-flex-center-between mb-3">
                <h4>Editar material</h4>
                <button class="btn btn-light" @click.prevent="$emit('end',false)">&larr; Lista de recursos</button>
            </div>
        </div>

        <div class="col-12">
            <form>
                <div class="form-row mb-4">
                    <div class="col-12 col-md-6 mb-3">
                        <label class="form-label">Título del recurso*</label>
                        <input type="text" class="form-control" v-model="resource.title">
                        <div class="text-danger mt-1" v-if="error.title">{{error.title}}</div>
                    </div>
                    <div class="col-12 col-md-6 mb-3">
                        <label class="form-label">Categoría*</label>
                        <select class="form-select" v-model="resource.category">
                            <option :value="null" selected disabled>Seleccione una categoría</option>
                            <option :value="category.label" v-for="(category,c) in categories" :key="c">{{category.label}}</option>
                        </select>
                        <div class="text-danger mt-1" v-if="error.category">{{error.category}}</div>
                    </div>
                    <div class="col-12 mb-3">
                        <label class="form-label">Descripción</label>
                        <textarea class="form-control" style="height:42px !important" v-model="resource.description"></textarea>
                    </div>
                    <div class="col-12 mb-3">
                        <label class="form-label">Portada o miniatura</label>
                        <div class="text-danger mb-1" v-if="error.cover">{{error.cover}}</div>
                        <input type="file" accept="image/*" id="file-cover" @change="handleImagePicked($event,resource)"/>
                        <div class="d-flex-center-between image-box">
                            <button class="btn btn-primary" @click.prevent="openFileDialog('file-cover')">Seleccionar</button>
                            <img :src="resource.cover" alt="" v-if="resource.cover">
                            <div class="bg-light no-image" v-else @click.prevent="openFileDialog('file-cover')">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-image" viewBox="0 0 16 16"> <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/> <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"/> </svg>
                            </div>
                            <button class="btn btn-danger rounded-circle p-2" @click.prevent="removeImage(resource)" v-if="resource.cover">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16"> <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/> </svg>
                            </button>
                        </div>
                </div>


                <div class="col-12 mb-3 mt-1">
                    <h5>Agregue material a este recurso</h5>
                </div>

                <div class="col-12 mb-3 mt-1">
                    <label class="form-label w-100">Contenido en texto</label>
                    <textarea class="w-100" rows="10" v-model="resource.text"></textarea>
                </div>

                <div class="col-12 mb-3">
                    <label class="form-label">Video URL</label>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" placeholder="httos://www...." v-model="resource.video_url">
                        <div class="input-group-append">
                            <span class="input-group-text">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16"> <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/> <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/> </svg>
                            </span>
                        </div>
                    </div>
                    <!-- <div class="text-danger mt-1" v-if="error.title">{{error.title}}</div> -->
                </div>

                <div class="col-12  mb-3">
                    <input type="file" accept="application/pdf" id="input-file" @change="handleFileUpload($event)"/>

                    <div class="form-label">Material en PDF</div>
                        <small class="text-danger" v-if="error.file">&otimes; {error.file}</small>
                        <div class="d-flex justify-content-between image-box">
                            <button class="btn btn-primary" style="height:auto !important" @click.prevent="openFileDialog('input-file')">Seleccionar archivo PDF</button>
                            
                            <div class="file-data flex-wrap flex-row p-2" style="height:auto !important" v-if="files.length">
                                <div class="border border-light rounded p-2 mb-2 w-100" v-for="(file,p) in files" :key="p">
                                    <div class="row">
                                        <div class="col">
                                            <div class="w-100">
                                                {{file.name}} 
                                                <div class="badge badge-light ml-2" v-if="file.size!=null">{{file.size}} kb</div>
                                                <button class="btn btn-danger btn-sm rounded" @click.prevent="removeFile(file.id)" v-if="file.load>99">Eliminar</button>
                                            </div>
                                            <div class="progress w-50 mt-3" style="height: 4px;" v-if="file.size!=null">
                                                <div :class="'progress-bar-'+file.id" role="progressbar" :style="{width: file.load + '%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            <!-- <button class="btn btn-danger rounded-circle p-2" style="top: 40px;" @click.prevent="removeFile(file.id)" v-if="file.load>99">
                                                <svg xmlns="http://www.w3.org/2000/svg" style="bottom:0" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16"> <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/> </svg>
                                            </button> -->
                                            
                                        </div>
                                        <!-- <div class="col">
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="bg-light no-image" style="height:80px !important" v-else @click.prevent="openFileDialog('input-file')">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"/> </svg>
                        </div>
                        <!-- <button class="btn btn-danger rounded-circle p-2" @click.prevent="removeFile()" v-if="file.load>99">
                            <svg xmlns="http://www.w3.org/2000/svg" style="bottom:0" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16"> <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/> </svg>
                        </button> -->
                    </div>
                </div>

                <div class="col-12 mb-3">
                    <label class="form-label">Material en imágenes</label>
                    <input-file :titulo="'Seleccionar imágenes'" :pdf="false" @uploading="trigger.uploading++" :update="resource.images" :key="trigger.imagesKey" :multiple='true' :error="{estado: false,mensaje:''}" @seleccion="handleFiles($event)"></input-file>
                    </div>
                </div>

                <div class="form-row">
                    <div class="col text-right">
                        <button class="btn btn-md btn-success" @click.prevent="submit" v-if="trigger.uploading<1">Actualizar</button>
                        <button class="btn btn-md btn-success" @click.prevent="submit" v-else disabled>
                            <div class="spinner-border" style="width:12px;height:12px" role="status">
                                <span class="sr-only">Loading...</span>
                            </div> Guardando archivos
                        </button>
                    </div>
                </div>
            </form>
        </div>

    </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
import 'firebase/storage';
import {isEmpty,putBase64,getDataFrom} from '@/utils';
import InputFile from '@/components/InputFile.vue'
export default {
    name:'CreateResource',
    props:{selected:Object},    
    components:{InputFile},
    data() {
        return {
            resource:{
                title:null,
                description:null,
                video_url:null,
                files:null,
                images:[],
                cover:null,
            },
            categories:null,
            validProps:['title','cover'],
            error:{
                title:null,
                file:null,
            },
            files:[],
            trigger:{uploading:false,imagesKey:0},
        }
    }, 
    methods:{
        removeFile(id){
            let fileIndex = this.files.findIndex((el)=>el.id==id);
            this.files.splice(fileIndex,1);
            // this.resource.files = null;this.file={name:null,load:0,size:null}
        },
        handleFileUpload(e){
            let file = e.target.files[0];

            this.error.file = null;
            if (file.type != 'application/pdf') {
                this.error.file = 'Solo es posible cargar PDF en este campo.';
                return null;
            }
                // filePushed.name = filePushed.id + "_" + file.name.replace(/[^a-z0-9]/gi,'_');

            let pdf = {
                name:null,
                size:(file.size/1024).toFixed(2),
                load:0,
                url:null,
                id:Date.now()
            }



            this.files.push(pdf);
            let filePushed = this.files.at(-1);
            filePushed.name = filePushed.id + "_" + file.name.replace(/[^a-z0-9]/gi,'_');

            // this.file.name = file.name;
            // this.file.size = (file.size/1024).toFixed(2);

            // Set name with normalized title like (example_title_1)
            // if(!name) name = file.name.replace(/[^a-z0-9]/gi,'_');
            // Add the timestamp
            // name += '_' + Date.now();
            // Add the file extension
            // name += '.' + file.name.split('.').pop();

            this.trigger.uploading++;
            var uploadTask = firebase.storage().ref().child(`capacitacion/files/${filePushed.name}`).put(file);

            uploadTask.on('state_changed',
                (snapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    filePushed.load = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    
                    console.log('Upload is ' + filePushed.load  + '% done');
                    this.$forceUpdate();
                },
                (error) => {
                    alert('Hubo un error al subir el PDF');
                    console.error(error);
                },
                () => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        filePushed.url = downloadURL;
                        // this.files.push(pdf)
                        console.log('File available at', downloadURL);
                        document.getElementsByClassName('progress-bar-'+filePushed.id)[0].style.background = '#10b759';
                        if(this.trigger.uploading){
                            this.trigger.uploading--;
                            if (this.trigger.uploading < 0) this.trigger.uploading = 0;
                        }
                    });
                }
            );
        },
        handleFiles(images){
            this.resource.images = images;
            if(this.trigger.uploading){
                this.trigger.uploading--;
                if (this.trigger.uploading < 0) this.trigger.uploading = 0;
            }
        },
        submit(e){
             e.target.innerHTML = `
                <div class="spinner-border" style="width:12px;height:12px" role="status">
                    <span class="sr-only">Loading...</span>
                </div> Creando
            `
            e.target.disabled = true;

            for (let input of document.querySelectorAll('form input,form select,form textarea,form button')) {
                input.disabled = true;
            }

            if(!this.isValid()){
                // Change the button state
                e.target.innerHTML = 'Crear recurso';
                e.target.disabled = false;
                for (let input of document.querySelectorAll('form input,form select,form textarea,form button')) {
                    input.disabled = false;
                }
                // Exit cuz invalid form
                return;
            }

            /**
             * The form is valid
             */

            // Save only pdf url's
            if(this.files.length){
                this.resource.files = this.files.map((file) => file.url);
            }            

            firebase.database().ref(`resources/${this.selected.fireId}`).update(this.resource).then(()=>{
                console.info('%c✓ resource updated','color:#0f0');
                this.$emit('end',true);
            });
        },
        isValid(){
            let valid = true;
            this.validProps.forEach((prop) => {
                this.error[prop] = '';
                if (isEmpty(this.resource[prop])) {
                    valid = false;
                    this.error[prop] = 'No puede estar vacio';
                }
            });
            return valid
            
        },
        openFileDialog(input) { document.getElementById(input).click() },
        removeImage(entity){ 
            entity.cover = null
            if(this.trigger.uploading){
                this.trigger.uploading--;
                if (this.trigger.uploading < 0) this.trigger.uploading = 0;
            }
         },
        handleImagePicked(e,entity){
            let file = e.target.files[0];

            let reader = new FileReader()
            reader.onload = (event) => {
                let img = new Image();
                img.src = event.target.result;
    
                img.onload = () => {
                    let canvas = document.createElement('canvas')
                    const MAX_WIDTH = 900
                    const MAX_HEIGHT = 900
                    let width = img.width
                    let height = img.height
    
                    if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width
                        width = MAX_WIDTH
                    }
                    } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height
                        height = MAX_HEIGHT
                    }
                    }
                    canvas.width = width
                    canvas.height = height
                    let ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0, width, height)
                    let data = canvas.toDataURL();

                    entity.cover = data;
                    this.$forceUpdate();
                    this.trigger.uploading++;
                    
                    putBase64(data).then((url)=>{
                        this.trigger.uploading--;
                        if (this.trigger.uploading < 0) this.trigger.uploading = 0;
                        if (entity.cover) {
                            entity.cover = url;
                        }
                    });
                }
            }
            reader.readAsDataURL(file)
        },
    },
    mounted() {
        getDataFrom('resource_categories').then((data)=>{
            this.categories=data;

            this.resource = JSON.parse(JSON.stringify(this.selected));
            if(this.resource['category'] == undefined) this.resource['category'] = null;

            if(this.selected.files!=undefined && Array.isArray(this.selected.files)){
                this.files = this.selected.files.map((url)=>{
                    let name = url.split('files%2F')[1];
                    name = name.split('?alt')[0];
                    return {
                        name:name,
                        size:null,
                        load:100,
                        url:url,
                        id:name.split('_')[0]
                    }
                })
            }else {
                let name = this.selected.files.split('files%2F')[1];
                name = name.split('?alt')[0];
                this.files.push({
                    name:name,
                    size:null,
                    load:100,
                    url:this.selected.files,
                    id:name.split('_')[0]
                });
            }

            this.trigger.imagesKey++;
        });
    },  
}
</script>

<style scoped>


input[type="file"] {
  display: none;
}

.image-box .file-data {
    height: 80px;
    display: flex;
    width: 79%;
    border-radius: 4px;
    border: 1px solid #000;
    justify-content: center;
    padding-left: 1rem;
    flex-flow: wrap column;
}

/* .image-box .progress {

} */

.image-box .btn-primary {
    width: 20%;
    height: 80px;
}
.image-box .btn-danger {
    position: absolute;
    right: 32px;
}

.image-box .no-image {
    height: 80px;
    width: 79%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-image svg {
    width: 50px;
    height: 50px;
    opacity: .2;
}


input[type="file"] {
  display: none;
}

.image-box img {
    height: 132px;
    display: block;
    width: 79%;
    object-fit: cover;
    border-radius: 4px;
}

.image-box .btn-primary {
    width: 20%;
    height: 132px;
}
.image-box .btn-danger {
    position: absolute;
    right: 32px;
    border: 2px solid white;
}

.image-box .no-image {
    height: 132px;
    width: 79%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-image svg {
    width: 50px;
    height: 50px;
    opacity: .2;
}
</style>